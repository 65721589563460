/** ApiService is used to make ajax call :GET/POST*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NameValueData } from '../../../models/name.value.model';
import { HttpRequestOption } from '../../../models/http.request.option.model';
import { AlertMessage, ConfirmationMessage } from '../../../models/alert.message.model';
import { Util } from '../../../modules/shared/common/util';
import { ApiUrlConfig } from '../common/api.url';
import { Globals } from '../common/globals';
import { AppEnum } from '../common/enums';
import { ayObject } from '../interface/shared-interface';
import { LocalStorageService } from './webstorage.service';
import { apiList } from './apisForBearerAuthorization';
import { Observable } from 'rxjs/internal/Observable';

declare let app: any;

const APIEndpoint = environment.apiBaseUrl;

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    global: Globals;

    /** constructor - Http & Router are injected  */
    constructor(private http: HttpClient, private storageService: LocalStorageService) {
        this.global = Globals.getInstance();
        window.addEventListener('storage', (e) => {
            if (
                (e.key === AppEnum.windowStorage.change_customer || e.key === AppEnum.windowStorage.ag_change_customer) &&
                !document.hasFocus()
            ) {
                window.location.reload();
            }
        });
    }

    /** getServiceCall function will be used to do get ajax call on server. Input: model of HttpRequestOption */
    /** TODO: make this function private */
    getServiceCall(option: HttpRequestOption,isDownload = false, fileName?: string): Promise<any> {
        // Calling common function to get the request header
        const reqHeader = this.getRequestHeader(option);
        let reqOption: ayObject;
        reqOption = { headers: reqHeader };
        if(isDownload) {
            reqOption = { headers: reqHeader, responseType: 'Blob' };
        }
        
        // if requestOption is given then merge with headers
        if (option.requestOption) {
            reqOption = { ...reqOption, ...option.requestOption };
        }

        return new Promise((resolve, reject) => {
            this.addCsrfCode(option);
            const url = this.getFullUrl(option, 'GET');

            // Http Get Call
            this.http
                .get(url, reqOption)
                .toPromise()
                .then((response: any) => {
                        // Check if the response is a Blob (PDF)
                    if(isDownload){
                        this.handleBlobResponse(response, resolve,fileName);
                    }else{
                        this.handleSuccessResponse(response ? response : {}, resolve);
                    }
                })
                .catch((error: HttpErrorResponse) => {
                    // Handle the error response
                    this.handleErrorResponse(error, reject);
                });
        });
    }
    /** postServiceCall function will be used to do post ajax call on server. Input: model of HttpRequestOption */
    postServiceCall(option: HttpRequestOption): Promise<any> {
        // Calling common function to get the request header
        this.addCsrfCode(option);
        const reqHeader = this.getRequestHeader(option);
        const reqOption = { headers: reqHeader };
        return new Promise((resolve, reject) => {
            // Get full Url as caller has to pass the relative url
            const url = this.getFullUrl(option, 'POST');
            // Http Post Call
            this.http
                .post(url, option.postData, reqOption)
                .toPromise()
                .then((response) => {
                    this.handleSuccessResponse(response ? response : {}, resolve);
                })
                .catch((error: HttpErrorResponse) => {
                    // Delegate error handling to handleErrorResponse method
                    this.handleErrorResponse(error, reject);
                });
        });
    }
    /** postServiceCall function will be used to do post ajax call on server. Input: model of HttpRequestOption */
    PatchServiceCall(option: HttpRequestOption): Promise<any> {
        // Calling common function to get the request header
        this.addCsrfCode(option);
        const reqHeader = this.getRequestHeader(option);
        const reqOption = { headers: reqHeader };
        return new Promise((resolve, reject) => {
            // Get full Url as caller has to pass the relative url
            const url = this.getFullUrl(option, 'PATCH');
            // Http Post Call
            this.http
                .patch(url, option.postData, reqOption)
                .toPromise()
                .then((response) => {
                    this.handleSuccessResponse(response ? response : {}, resolve);
                })
                .catch((error: HttpErrorResponse) => {
                    // Delegate error handling to handleErrorResponse method
                    this.handleErrorResponse(error, reject);
                });
        });
    }

    /** postServiceCall function will be used to do post ajax call on server. Input: model of HttpRequestOption */
    DeleteServiceCall(option: HttpRequestOption): Promise<any> {
        // Calling common function to get the request header
        this.addCsrfCode(option);
        const reqHeader = this.getRequestHeader(option);
        const reqOption = { headers: reqHeader };
        return new Promise((resolve, reject) => {
            // Get full Url as caller has to pass the relative url
            const url = this.getFullUrl(option, 'DELETE') + '?' + new HttpParams({fromObject: option.postData}).toString();
            // Http Post Call
            this.http
                .delete(url, reqOption)
                .toPromise()
                .then((response) => {
                    this.handleSuccessResponse(response ? response : {}, resolve);
                })
                .catch((error: HttpErrorResponse) => {
                    // Handle the error response
                    this.handleErrorResponse(error, reject);
                });
        });
    }

    /** downloadServiceCall function will be used to do download any doc on server. Input: model of HttpRequestOption */
    downloadServiceCall(option: HttpRequestOption): Promise<any> {
        // Calling common function to get the request header
        return new Promise(() => {
            // Get full Url as caller has to pass the relative url
            const url = this.getFullUrl(option, 'GET');
            window.open(url, '_blank');
        });
    }

    /** getFullUrl function will be used to get the API full Url for the given relative Url */
    private getFullUrl(option: HttpRequestOption, methodType: string): string {
        let fullUrl = '';
        // If relative Url is start with '/' then removed the '/' as apiBaseUrl contains the '/' at the end
        if (option.url.substring(0, 1) === '/') {
            fullUrl = environment.apiBaseUrl + option.url.substring(1);
        } else {
            fullUrl = environment.apiBaseUrl + option.url;
        }

        return this.appendParamValues(fullUrl, methodType, option.getData);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private appendParamValues(fullUrl: string, method: string, _param: object) {
        const fullParamObject: ayObject = _param;
        for (const key in fullParamObject) {
            if (fullParamObject.hasOwnProperty(key)) {
                const extraParam = key + '=' + fullParamObject[key];
                fullUrl = this.addParameterToURL(fullUrl, extraParam);
            }
        }

        return fullUrl;
    }

    /** appendParams function will be used to append oauthParams in url  */
    addParameterToURL(_url: string, param: string): string {
        _url += (_url.split('?')[1] ? '&' : '?') + param;
        return _url;
    }

    /** getOauthParams function will be used to get the oauthParams based on api call  */
    getOauthParams(requestType: string = ''): ayObject {
        const time = new Date();
        const oa_timestamp = (Math.floor(time.getTime() / 1000) as any) as string; // current timezone
        const oa_nonce = Math.random().toString(36).substring(2, 8); // random string generate of any len, we are using 8 chars
        let oauthParams: ayObject = {};
        if (requestType === 'login') {
            oauthParams = {
                oauth_signature_method: 'HMAC-SHA1',
                oauth_version: '1.0',
                oauth_timestamp: oa_timestamp,
                oauth_nonce: oa_nonce
            };
        } else if (requestType === 'accessToken') {
            oauthParams = {
                oauth_token: this.storageService.get('oauth_token'),
                oauth_signature_method: 'HMAC-SHA1',
                oauth_version: '1.0',
                oauth_timestamp: oa_timestamp,
                oauth_nonce: oa_nonce,
                oauth_verifier: this.storageService.get('oauth_verifier'),
                userId: this.storageService.get('userId')
            };
        } else {
            oauthParams = {
                oauth_token: this.storageService.get('oauth_token'),
                oauth_token_secret: this.storageService.get('oauth_token_secret'),
                oauth_signature_method: 'HMAC-SHA1',
                oauth_version: '1.0',
                oauth_timestamp: oa_timestamp,
                oauth_nonce: oa_nonce
            };
        }

        if (this.storageService.get('key')) {
            oauthParams.oauth_consumer_key = this.storageService.get('key');
        }

        return oauthParams;
    }

    /** getRequestHeader function will be used to get the request header that will be passed to any AJAX call */
    private getRequestHeader(option: HttpRequestOption) {
        // Get the accessToken from the storage...
        const allowUrl = this.filterAPIForAuthorization(option.url);
        const notAllowUrl = this.notAllowUrl(option.url);

        const accessToken = this.storageService.get(environment.authTokenKey);

        // Create the request header - adding Content-Type in request header that caller has passed
        const headersObject: ayObject = {};
        const httpHead: ayObject = { 'Content-Type': option.contentType };

        // headers = headers.append('Content-Type', option.contentType);
        headersObject['Content-Type'] = option.contentType;
        // Check access token if not there - pass the request headers with Authorization
       // if (accessToken !== undefined && accessToken !== null && accessToken.length > 0 /*&& allowUrl*/) {
        if (accessToken !== undefined && accessToken !== null && accessToken.length > 0 && !notAllowUrl) {
            delete httpHead.Authorization;
            // Bearer authentication implemented at server side...
            const combinedToken = 'Bearer ' + accessToken;
            headersObject.Authorization = combinedToken;
        }

        let headers = new HttpHeaders(httpHead);

        // In case if caller has passed any additional header that will be added in request header..
        if (option.headers !== undefined && option.headers !== null) {
            option.headers.forEach((headerItem: NameValueData) => {
                headersObject[headerItem.name] = headerItem.value;
            });
        }
        headers = new HttpHeaders(headersObject);
        return headers;
    }

    /**
     * this function will be called when logout and clear all data
     */
    private clearAllData(errorCode = 0) {
        this.storageService.remove('userId');
        window.localStorage.setItem('change_customer', '');
        this.storageService.remove('change_customer');
        Util.hideLoading();
    }

    showConfirmationMessage(errorCode = 0): void {
        const confirmMessage = new ConfirmationMessage();
        confirmMessage.type = AlertMessage.Type.warning;
        confirmMessage.title = 'Oops!!';
        confirmMessage.isShowCancelButton = false;
        if (Number(errorCode) === 7000) {
            confirmMessage.text = 'Session has expired please login again.';
        }
        if (Number(errorCode) === 9111) {
            confirmMessage.text = 'Your account is deactivated. Please contact administrator.';
        }
        if (Number(errorCode) === 7001) {
            confirmMessage.allowOutsideClick = false;
            confirmMessage.text = 'Token has expired please try again.';
        }
        confirmMessage.confirmButtonText = 'Ok';
        confirmMessage.okCallBack = async () => {
            this.global.setVal('isLogout', 1);
            location.reload();
        };
        confirmMessage.cancelCallBack = async () => {
            this.global.setVal('isLogout', 1);
            location.reload();
        };
        Util.showConfirmationDialog(confirmMessage);
    }

    public getCsrfCode(formName: string): void {
        // Create the HttpRequestOption
        const reqOpt: HttpRequestOption = new HttpRequestOption();
        reqOpt.url = ApiUrlConfig.CSRF.getCsrfCode;

        reqOpt.getData = {
            formName
        };

        this.getServiceCall(reqOpt)
            .then((result) => {
                this.global.setVal(reqOpt.getData.formName, result);
            })
            .catch(() => {});
    }

    private addCsrfCode(option: HttpRequestOption) {
        try {
            if ('formName' in option.postData) {
                const csrfData = this.global.getVal(option.postData.formName);
                option.postData.csrfCode = csrfData.csrf_token;
                option.postData.timestamp = csrfData.timestamp;
            }
        } catch (error) {}

        try {
            if ('formName' in option.getData) {
                const csrfData = this.global.getVal(option.getData.formName);
                option.getData.csrfCode = csrfData.csrf_token;
                option.getData.timestamp = csrfData.timestamp;
            }
        } catch (error) {}
    }
    
    showAlert(message: string = ''): void {
      Util.hideLoading();
      const confirmMessage = new ConfirmationMessage();
      confirmMessage.type = AlertMessage.Type.warning;
      confirmMessage.title = 'Oops!!';
      confirmMessage.allowOutsideClick = true;
      confirmMessage.text = message;
      confirmMessage.confirmButtonText = 'Ok';
      confirmMessage.isShowCancelButton = false;
      Util.showConfirmationDialog(confirmMessage);
  }

    // handle response for successful get/post request
    private handleSuccessResponse(response: ayObject, resolve: any): void {
        // Handle the success response
        if (response === null) {
            resolve(response);
        } else if (Number(response.code) === 9111 || Number(response.code) === 7000 || Number(response.code) === 7001) {
            this.clearAllData(response.code);
            this.showConfirmationMessage(response.code);
        } else if (Number(response.code) === 7002) {
            this.showAlert(response.status);
        } else {
            resolve(response);
        }
    }

    private handleErrorResponse(error: any, reject: any): void {
    
        if (error?.status === 500 || error?.status === 504 || error?.status === 503) {
            // Handle 500 errors without showing a popup
            console.warn('Server Error');
            reject(error);
            return;
        }
    
        if (error?.status === 0 && error?.message.includes('Unknown Error')) {
            // Handle CORS errors
            console.warn('CORS error or network issue detected.');
            reject({
                message: 'CORS error or network issue. Please check your network and API configuration.',
                originalError: error
            });
            return;
        }
    
        if (error?.error) {
            // Normalize the keys of the error object to lowercase
            const normalizedError = Object.keys(error.error).reduce((acc, key) => {
                acc[key.toLowerCase()] = error.error[key];
                return acc;
            }, {} as { [key: string]: any });
    
            const errorCode = Number(normalizedError['code']);
            const errorMessage = normalizedError['message'];
    
            if (errorCode && errorMessage) {
                this.showAlert(errorMessage);
            } else if ([8000, 8001, 9111, 7000, 7001].includes(errorCode)) {
                this.clearAllData(errorCode); // Clear data for specific error codes
                this.showConfirmationMessage(errorCode); // Show a confirmation message
            } else {
                this.showAlert('Unexpected error occurred. Please try again later.');
            }
        }
    
        // Reject the error for further handling
        reject(error);
    }
    
    
    // filter api for authorization
    private filterAPIForAuthorization(url: string) {
        const urlSegments = url.split('/');
        let res = false;
        if (urlSegments.length === 2) {
            const requestClass = urlSegments[0].trim();
            const requestMethod = urlSegments[1].trim();
            if (apiList[requestClass] && requestMethod && apiList[requestClass].indexOf(requestMethod) > -1) {
                res = true;
            }
        }
        return res;
    }
    // filter api for authorization
    private notAllowUrl(url: string) {
        const urlSegments = url.split('/');
        let res = false;
        if (urlSegments.length === 2) {
            //const requestClass = urlSegments[0].trim();
            const requestMethod = urlSegments[1].trim();
            if (requestMethod === 'getOtp') {
                res = true;
            }
        }
        return res;
    }
    /**
     * Function to set request options and call service with get method
     *
     * @param url
     * @param params
     * @param isDownload
     * @param requestOption
     * @returns any
     * @author Manesh Raval
     */
    callGetApi(url: string, params: ayObject, isDownload: boolean = false, fileName?: string , requestOption: any = {} ): any {
        const reqOpt: HttpRequestOption = new HttpRequestOption();
        reqOpt.url = url;
        reqOpt.getData = params;

        // If requestOptions passed in argument then set the options and text/plain for contentType
        if (requestOption) {
            reqOpt.requestOption = requestOption;
            reqOpt.contentType = 'text/plain';
        }

        // If isDownload set then return blank object call download service
        if (isDownload) {
            params.userId = app.userId;
            reqOpt.getData = params;
            Object.keys(reqOpt.getData).sort().reverse();
            this.getServiceCall(reqOpt,isDownload, fileName);
            return {};
        } else {
            reqOpt.getData = params;
            return this.getServiceCall(reqOpt);
        }
    }

    /**
     * Function to set request options and call service with post method
     *
     * @param url
     * @param params
     * @returns any
     * @author Manesh Raval
     */
    callPostApi(url: string, params: ayObject): any {
        const reqOpt: HttpRequestOption = new HttpRequestOption();
        reqOpt.url = url;
        reqOpt.postData = params;
        return this.postServiceCall(reqOpt);
    }

    callCommonApi(url: string, params: ayObject, method: string): any {
        const reqOpt: HttpRequestOption = new HttpRequestOption();
        reqOpt.url = url;
        reqOpt.postData = params;
        switch(method) {
            case 'POST' :
                return this.postServiceCall(reqOpt);
            case 'PATCH' :
                return this.PatchServiceCall(reqOpt);
            case 'DELETE' :
                return this.DeleteServiceCall(reqOpt);
        }
    }

    /**
   * Function to upload a file to the server.
   * @param url The API endpoint to which the file is uploaded.
   * @param file The file to be uploaded.
   * @param otherParams Additional parameters to include in the request.
   * @param fileKey The key under which the file will be stored in the FormData.
   * @returns An Observable that represents the HTTP request.
   */
    uploadFile(url: string, file: File, otherParams: { [key: string]: any } = {}, fileKey: string = 'fileKey') {

        const formData: FormData = new FormData();

        formData.append(fileKey, file, file.name);

        if (Object.keys(otherParams).length > 0) {
            for (const key in otherParams) {
                if (otherParams.hasOwnProperty(key)) {
                    formData.append(key, otherParams[key]);
                }
            }
        }

        const headersObject: ayObject = {};
        
        headersObject.Authorization = 'Bearer ' + this.storageService.get(environment.authTokenKey);

        let reqHeader = new HttpHeaders(headersObject);

        let reqOption: ayObject;
        reqOption = { headers: reqHeader };

        return this.http.post<any>(APIEndpoint + url, formData, reqOption);
    }
    /**
     * Handles the Blob (PDF) response, triggering a file download.
     *
     * @param blob - The Blob object representing the file to be downloaded.
     * @param resolve - A function to resolve the associated promise after the download is initiated.
     * @param fileName - The optional name of the file to be downloaded (default is an empty string).
     */
    private handleBlobResponse(blob: Blob, resolve: Function, fileName = ''): void {
        // Handle the Blob (PDF) response here
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = 'Agyield_' + fileName; // You can customize the filename
        a.click();
        window.URL.revokeObjectURL(url);

        resolve(); // Resolve the promise once the download is initiated
    }

     
    /**
     * Function to set request options and call service with get method
     *
     * @param url
     * @param params
     * @returns any
     * 
     */
    callStaticGetAPI(url: string, params: ayObject){
        const reqOpt: HttpRequestOption = new HttpRequestOption();
        reqOpt.url = url;
        reqOpt.getData = params;

        const reqHeader = this.getRequestHeader(reqOpt);
        let reqOption: ayObject;
        reqOption = { headers: reqHeader };
        
        return new Promise((resolve, reject) => {
            this.addCsrfCode(reqOpt);
            const url = this.appendParamValues(reqOpt.url, 'GET', reqOpt.getData)

            // Http Get Call
            this.http
                .get(url, reqOption)
                .toPromise()
                .then((response: any) => {
                        // Check if the response is a Blob (PDF)
                    this.handleSuccessResponse(response ? response : {}, resolve);
                })
                .catch((error: HttpErrorResponse) => {
                    // Handle the error response
                    this.handleErrorResponse(error, reject);
                });
        });
    }

    /**
     * Makes an HTTP API call with specified parameters and options.
     * @param url The URL for the API call.
     * @param params The parameters to be sent with the request.
     * @param method The HTTP method to use (POST or GET).
     * @param responseType The type of response expected (json, text, or blob).
     * @param isDownload Indicates if the response should be treated as a file download.
     * @param fileName Optional file name for the downloaded file.
     * @returns A Promise that resolves with the response data.
     */
    callApi(
        url: string,
        params: any,
        method: 'POST' | 'GET' = 'POST',
        responseType: 'json' | 'text' | 'blob' = 'json',
        isDownload: boolean = false,
        fileName?: string
    ): Promise<any> {
        // Initialize request options
        const reqOpt: HttpRequestOption = new HttpRequestOption();
        reqOpt.url = url;
        reqOpt.postData = params;

        // Set up request headers and options
        this.addCsrfCode(reqOpt); // Add CSRF token to request options
        const reqHeader = this.getRequestHeader(reqOpt); // Get request headers
        const reqOption: { headers: HttpHeaders; responseType: 'json' | 'text' | 'blob'; observe: 'response' } = {
            headers: reqHeader,
            responseType: responseType as 'json' | 'text' | 'blob',
            observe: 'response' // Ensure to get the full HttpResponse object
        };

        // Adjust responseType if downloading a file
        if (isDownload) {
            reqOption.responseType = 'blob'; // Ensure correct response type for file download
        }

        // Construct full URL with parameters for GET requests
        const fullUrl = this.getFullUrl(reqOpt, method);

        return new Promise((resolve, reject) => {
            // Choose the appropriate HTTP method
            const httpCall = method === 'POST'
                ? this.http.post(fullUrl, reqOpt.postData, reqOption as any)
                : this.http.get(fullUrl, { ...reqOption as any, params });

            // Handle the HTTP call and response
            httpCall.toPromise()
                .then((response: any) => {
                    // Extract the response body
                    const responseBody = response.body;

                    if (isDownload) {
                        // Handle download scenarios
                        if (responseBody instanceof Blob) {
                            this.handleBlobResponseCallAPI(responseBody, resolve, fileName);
                        } else if (responseBody instanceof ArrayBuffer) {
                            const blob = new Blob([responseBody]);
                            this.handleBlobResponseCallAPI(blob, resolve, fileName);
                        } else {
                            console.log('Unexpected response type for download');
                            reject('Unexpected response type for download');
                        }
                    } else {
                        // Handle non-download scenarios
                        this.handleSuccessResponseCallAPI(responseBody, resolve);
                    }
                })
                .catch((error: HttpErrorResponse) => {
                    // Handle HTTP error
                    console.error('HTTP error:', error);
                    this.handleErrorResponse(error, reject);
                });
        });
    }

    /**
     * Handles blob responses, including file download.
     * @param blob The Blob object to handle.
     * @param resolve Function to resolve the promise with the Blob.
     * @param fileName Optional file name for the download.
     */
    private handleBlobResponseCallAPI(blob: Blob, resolve: (value?: any) => void, fileName?: string): void {
        if (fileName) {
            // Create a download link for the file
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url); // Clean up object URL
        }
        resolve(blob); // Resolve the promise with the Blob
    }

    /**
     * Handles successful API responses.
     * @param response The response data.
     * @param resolve Function to resolve the promise with the response data.
     */
    private handleSuccessResponseCallAPI(response: any, resolve: (value?: any) => void): void {
        if (response === null) {
            resolve(response); // Resolve with null if response is null
        } else if (Number(response.code) === 9111 || Number(response.code) === 7000 || Number(response.code) === 7001) {
            // Handle specific response codes
            this.clearAllData(response.code);
            this.showConfirmationMessage(response.code);
        } else if (Number(response.code) === 7002) {
            this.showAlert(response.status); // Handle another specific response code
        } else {
            resolve(response); // Resolve with the response data for other cases
        }
    }

    /**
     * Function to upload a file to the server as a base64 string.
     * @param url The API endpoint to which the file is uploaded.
     * @param file The file to be uploaded.
     * @param otherParams Additional parameters to include in the request.
     * @param fileKey The key under which the file will be stored in the request body.
     * @returns An Observable that represents the HTTP request.
     */
    uploadFileBase64(url: string, file: File, otherParams: { [key: string]: any } = {}, fileKey: string = 'fileKey') {

        const headersObject: any = {};
        headersObject.Authorization = 'Bearer ' + this.storageService.get(environment.authTokenKey);

        let reqHeader = new HttpHeaders(headersObject);

        let reqOption: any = { headers: reqHeader };

        return new Observable(observer => {
            const reader = new FileReader();
            reader.onload = () => {
                // Convert file to base64 string
                const base64String = (reader.result as string).split(',')[1]; // Extract the base64 part
                const payload = {
                    ...otherParams,
                    [fileKey]: base64String, // Add base64 string to the payload
                    fileName: file.name,
                    fileType: file.type // Optional: You can also include the file's type
                };

                // Send the POST request with base64 data as JSON
                this.http.post<any>(APIEndpoint + url, payload, reqOption).subscribe(
                    response => observer.next(response),
                    error => observer.error(error),
                    () => observer.complete()
                );
            };
            reader.onerror = (error) => {
                observer.error(error);
            };

            // Start reading the file as Data URL (base64)
            reader.readAsDataURL(file);
        });
    }

}