/** CommonService is used to manage the common item in any component */
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
 // import Swal, { SweetAlertType } from 'sweetalert2';
import { navItems } from '../../../_nav';
import { AppEnum } from '../common/enums';
import { Globals } from '../common/globals';
import { ayObject } from '../interface/shared-interface';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class CommonService {    
    defaultTab = 0;
    _tabSource = new BehaviorSubject(this.defaultTab);
    currentTab = this._tabSource.asObservable();
    public navItems = navItems;
    global: Globals;
    urlMap = [
        ['pen-cash-transaction', '/pen-transactions'],
        ['pen-indemnity-transaction', '/pen-transactions'],
        ['pen-minmax-transaction', '/pen-transactions'],
        ['pen-other-revenue-transaction', '/pen-transactions'],
        ['pen-transaction-future', '/pen-transactions'],
        ['pen-transaction-option', '/pen-transactions'],
        ['custom-contract-transaction', '/transactions'],
        ['pen-firm-offer-transaction', '/pen-transactions'],
        ['firm-offer-transaction', '/transactions'],        
        ['pen-htambp-transaction', '/pen-transactions'],
        ['htambp-transaction', '/transactions'],
        ['pen-basis-transaction', '/pen-transactions'],
        ['basis-transaction', '/transactions'],
        ['pen-percentage-transaction', '/pen-transactions'],
        ['percentage-transaction', '/transactions'],
        ['minmax-transaction', '/transactions'],
        ['accumulator-transaction', '/transactions'],
        ['pen-transaction-future/entry','/pen-transactions'],
        ['transaction-future', '/transactions'],
        ['transaction-option', '/transactions'],
        ['indemnity-transaction', '/transactions'],
        ['other-revenue-transaction', '/transactions'],        
        ['pen-insurance/entry', '/pen-insurance'],        
        ['insurance/entry', '/insurance'],
        ['cash-transaction', '/transactions'],
        ['crops/entry', '/crops'],
        ['pens/entry', '/pens'],
        ['field/entry', '/field'],
        ['approve-portfolio-mbp', '/portfolio-mbp'],
        ['user/entry', '/user-list'],
        ['organization/entry', '/organization'],
        ['global-strategy/entry', '/global-strategy'],
        ['crop-default-new/entry', '/crop-default-new'],
        ['pen-expense', '/pen-expense'],
        ['expense', '/expense/crop'],
        // ['expense', '/expense/crop1'],
        ['croptype/entry', '/croptype'],
        ['global-strategy/gs-transaction-list', '/global-strategy'],
        ['transaction-roll-over', '/transactions'],
        ['add-news', '/news'],
        ['mbp-user/assign-buyer', '/mbp-user'],
        ['/mbp-user/manage-bushels', '/mbp-user'],
        ['/field-clu', '/field'],
        ['/published-strategies', '/athena'],
        ['/add-portfolio-mbp', '/portfolio-mbp'],
        ['/trading-account', '/user-list'],
        ['/discount-code/entry', '/discount-code'],
        ['/discount-code/report', '/discount-code'],
        ['/renew-plan', '/renew-plan'],
        ['customers/entry', '/customers'],
        ['advisors/entry', '/customers'],
        ['simulator-users/entry', '/simulator-users']

    ];

    allowedUrls = ['my-account'];

    // commonErrorEvent that can be handle at framework level
    commonErrorEvent = new EventEmitter();

    // CustomerSwitchedEvent that can be handle at framework level
    customerSwitchedEvent = new EventEmitter();

    // onYearChange will use when we change year at Add crop
    onYearChange = new EventEmitter();

    menuChange = new EventEmitter<any>();
    onMOChecked = new EventEmitter();
    /// for transaction cash sales count
    cashSaleCount = new EventEmitter();

    /// for transaction fo count
    foCount = new EventEmitter();

    /// for transaction Indemnity Payment count
    indemnityCount = new EventEmitter();

    /// for transaction Other Revenue count
    otherCount = new EventEmitter();

    tabNumber = new EventEmitter();

    activeYears = new EventEmitter();

    constructor(private router: Router) {
        this.global = Globals.getInstance();
    }
    /* to be used for change the tab of transaction */
    changeTab(_id: number): void {
        this._tabSource.next(_id);
    }
    
    callSwalWithOutConfirmation(message: string, icon: SweetAlertIcon): void {
        Swal.fire({
            showConfirmButton: false,
            text: message,
            timer: 4000,
            icon
        });
    }

    alertNormal(message: string): void {
        Swal.fire({
            text: message,
            showConfirmButton: false
        });
    }

    alertHTML(message: HTMLElement, width: number | string, fromChart: boolean): void {
        if (fromChart === true) {
            Swal.fire({
                html: message,
                showConfirmButton: false,
                width,
                didClose: () => {
                    window.scroll({
                        top: 0,
                        left: 0
                    });
                }
            });
        } else {
            Swal.fire({
                html: message,
                showConfirmButton: false,
                width
            });
        }
    }

    /**
     * checkUserAccess function used to check selected users access before it load to page from URL
     */
    checkUserAccess(path: string): void {
        let homeLink = '//';

        const allowedPaths = ['/my-account','/settings', '/portfolio-registration', '/renew-plan'];
        path = this.getUpdatedPath(path);
        
        const byPassPath = ['/set-password/', '/portfolio-registration/', '/forgot-password', '/forgot-password/', '/renew-plan/']
        
        // This will return If the path parameter contain byPassPath array
        if (allowedPaths.indexOf(path) >= 0 || byPassPath.some(v => path.includes(v))) {
            return;
        }        
        setTimeout(() => {
            let isSet = false;
            if (
                this.global.getVal('isMoMasterChecked') &&
                this.global.getVal('isMoMasterChecked').toString() === AppEnum.isMoMasterChecked.true
            ) {
                if (this.navItems[0] && this.navItems[0].name === 'Dashboard') {
                    homeLink = this.navItems[1].children[0].url;
                } else {
                    homeLink = this.navItems[0].children[0].url;
                }
            } else if (this.navItems[0] && this.navItems[0].name === 'Dashboard') {
                homeLink = this.navItems[0].url;
            } else {
                if(this.navItems[0].children !== undefined){
                    homeLink = this.navItems[0].children[0].url;
                }
            }
            for (const navItem of this.navItems) {
                if (navItem.children) {
                    if (
                        (navItem.name === 'Management' || path.includes('agyield-mbp')) &&
                        this.global.getVal('isMoMasterChecked').toString() === AppEnum.isMoMasterChecked.true
                    ) {
                        continue;
                    }
                    for (const item of navItem.children) {
                        if (item.children) {
                            for (const child of item.children) {
                                if (child.url === path && this.checkIfEnabled(item)) {
                                    isSet = true;
                                    break;
                                }
                            }
                        }
                        if (isSet || (item.url === path && this.checkIfEnabled(item))) {
                            isSet = true;
                            break;
                        }
                    }
                } else if (navItem.url === path && this.checkIfEnabled(navItem)) {
                    isSet = true;
                    break;
                }
                if (isSet) {
                    break;
                }
            }
            if (!isSet) {
                this.router.navigate([homeLink]);
            }
        }, 500);
    }

    /**
     * Function to validate if the option is disabled or not
     *
     * @param item: ayObject
     * @returns true
     * @author Manesh Raval
     */
    checkIfEnabled(item: ayObject): boolean {
        let result = false;
        if (item.attributes === undefined) {
            result = true;
        } else if (item.attributes.disabled === undefined) {
            result = true;
        } else if (!item.attributes.disabled) {
            result = true;
        }
        return result;
    }

    /**
     * Function to change path to parent if the child path is used to navigate.
     * To check if the path is allowed for the logged-in user.
     *
     * @param url: string
     * @returns string
     * @author Manesh Raval
     */
    getUpdatedPath(url: string): string {
        for (const urlMap of this.urlMap) {
            if (url.includes(urlMap[0])) {
                url = urlMap[1];
                break;
            }
        }
        return url;
    }
}