/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, EventEmitter } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import * as _ from 'underscore';
import { HttpErrorResponse } from '@angular/common/http';
import { navItems } from '../../_nav';
import { CommonService } from '../../modules/shared/services/common.service';
import { LocalStorageService } from '../../modules/shared/services/webstorage.service';
import { Globals } from '../../modules/shared/common/globals';
import { Util } from '../../modules/shared/common/util';
import { environment } from '../../../environments/environment';
import { AlertMessage, ConfirmationMessage } from '../../models/alert.message.model';
import { menuList } from '../../modules/shared/services/menuCombo';
import { AppConstants } from '../../modules/shared/common/constants';
import { AppEnum } from '../../modules/shared/common/enums';
import { menuListClone } from '../../modules/shared/services/menuComboClone';



import { ayDate, ayObject, YearList, User, PoolAccount, Crop } from '../../modules/shared/interface/shared-interface';
import { ApiService } from '../../modules/shared/services/api.service';
import { ApiUrlConfig } from '../../modules/shared/common/api.url';
import { filter } from 'rxjs';

declare let app: ayObject;

interface Notification {
    createdDtm: ayDate;
    id: number;
    isDismiss: number;
    isPush: number;
    message: string;
    notificationType: string;
}

interface Logout {
    code: number;
    status: string;
}
interface CustomerInfo {
    city: string;
    crop: number;
    currencyUnit: string;
    isMoMasterAccount: boolean;
    isPool: number;
    landUnit: string;
    orgName: string;
    orgType: string;
    planId: number;
    state: string;
    zipcode: string;
    elevator: string;
    email: string;
}
interface SwitchCustomer {
    customerInfo: CustomerInfo;
}

interface UserDetail extends User {
    loggedOrgType: string;
    cropCount: number;
    loggedUserId: number;
    loggedCustId: number;
    loggedCustName: string;
    isSuspended: number;
    loggedOrgId: number;
    loggedCustElevId: number;
    logged_organization_id: number;
    serverEnv: string;
    loggedCurrencyUnit: string;
    loggedLandUnit: string;
    loggedPlanId: number;
    isPool: number;
    basicUserAcres: number;
    isExpiredPool: number;
    isSponsor: boolean;
    isLivestock: number;
}

interface imageUrl {
    [index: number]: string;
}

interface SearchUserData {
    id: string;
    name: string;
    sup: string;
    isSponsor?: boolean;
}
@Component({
    selector: 'ay-app-dashboard',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
    public sidebarMinimized = true;
    keyword = 'name';
    public navItems = navItems;
    global: Globals;
    activeYears!: YearList[];
    notificationArr!: Notification[];
    public selectedOrgUser = '';
    public selectedOrgUserId = '';

    public searchUserData: SearchUserData[] = [];
    public showSearchbox = false;
    selectedYear = '';
    fontColor = 0;
    isUserLoaded = false;
    showUserDropdown = false;
    image = 'assets/img/avatar.png';
    yearEmit = 0;
    reload = true;
    tabId = '';
    navigationSubscriber: any;
    showHeader = false;
    homeLink = '\\';
    isFreeTrial = false;
    allowedPathsExceptMenu = [
        'pen-cash-transaction',
        'pen-indemnity-transaction',
        'pen-minmax-transaction',
        'pen-other-revenue-transaction',
        'cash-transaction',
        'custom-contract-transaction',
        'pen-firm-offer-transaction',
        'firm-offer-transaction',        
        'pen-htambp-transaction',
        'htambp-transaction',
        'pen-basis-transaction',
        'basis-transaction',
        'pen-percentage-transaction',
        'percentage-transaction',
        'htambp-transaction',
        'minmax-transaction',
        'accumulator-transaction',
        'transaction-future',
        'transaction-option',
        'indemnity-transaction',
        'other-revenue-transaction',
        'my-account',
        'settings',
        'add-news',
        'add-portfolio-mbp',
        'approve-portfolio-mbp',
        'resource',
        'trading-account',
        'alert',
        'pen-transaction-future',
        'pen-transaction-option',
        'pen-transaction-future/entry',
        'pen-transaction-option/entry/',
        'customers/entry/',
        'advisors/entry/',
    ];
    menuListClone: menuListClone;
    selectedStockType = '';
    stockTypes = [{'value' : 'grain', 'lable' : 'Crops'}, {'value' : 'livestock', 'lable' : 'Livestock'}]
    showSettingsMenu: boolean = false;
    @ViewChild('searchUser', { static: true }) searchElement: any;

    private disableHedgeRecomPlanTypes = [
        AppConstants.PLAN_TYPE_AGYIELD_PLUS,
        AppConstants.PLAN_TYPE_ENTERPRISE_LITE
    ];

    /** constructor: ApiService is injected and used to call the api for this component
     * WebStorageService is used to set , get , remove data in local storage
     * formBuilder is used to create form
     * router is used to navigation
     * commonService used access all common functions
     */
    constructor(
        private commonService: CommonService,
        private service: ApiService,
        private _router: Router,
        private _webStorageService: LocalStorageService
    ) {
        this.global = Globals.getInstance();
        this.reload = true;
        this.menuListClone = new menuListClone();
        this.searchElement = ElementRef;
        if (this.commonService.onYearChange.closed) {
            this.commonService.onYearChange = new EventEmitter();
        }

        if (this.commonService.onMOChecked.closed) {
            this.commonService.onMOChecked = new EventEmitter();
        }

        /** Change Year */
        this.commonService.onYearChange.subscribe((yearEmit: number) => {
            this.setUser(yearEmit);
        });

        this.commonService.onMOChecked.subscribe((isMoChecked: boolean) => {
            this.showUserDropdown = !isMoChecked;
            this.menuChanges();
        });

        if (this.commonService.menuChange.closed) {
            this.commonService.menuChange = new EventEmitter();
        }
        this.commonService.menuChange.subscribe((menuLists: string) => {
            if (typeof menuLists === 'string') {
                this.disableMenu(JSON.parse(menuLists));
            } else {
                this.disableMenu(menuLists);
            }
        });

        this.navigationSubscriber = _router.events.pipe(
                filter((event) => event instanceof NavigationStart)
            ).subscribe((event: any) => {
                if (
                    event instanceof NavigationStart &&
                    !(this._router.url.includes('resources/HedgeRecommendations') || this._router.url.includes('HedgeReportsAudio'))
                ) {
                    this._webStorageService.set('is_year_change', true);
                    this.reload = false;
                    this.ngOnInit();
                }
            });

        window.addEventListener('storage', (e) => {
            if (e.key === AppEnum.windowStorage.change_customer && !document.hasFocus()) {
                window.location.reload();
            }
        });
    }

    /* To Disable/Enable Menu*/
    disableMenu(obj: Array<any>): void {

        if (
            this.global.getVal('isMoMasterChecked').toString() === AppEnum.isMoMasterChecked.false &&
            this.global.getVal('userRole') !== AppConstants.USER_AY_SALES
        ) {
            this.navItems = [];
            const _index = obj.findIndex((m) => m.name === 'Performance');
            let cropCount = (this.global.getVal('isLivestock') && this.selectedStockType == 'livestock')? this.global.getVal('penCount') : this.global.getVal('cropCount');
            
            if (_index !== -1) {
                if (
                    cropCount > 0 ||
                    this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString() ||
                    this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()
                ) {
                    this.navItems = JSON.parse(this.global.getVal('menuCombination'));
                    this.enable(obj[_index]);
                } else {
                    this.disable(obj[_index]);
                    this.navItems = obj;
                }
            }
            const _index1 = obj.findIndex((m) => m.name === 'Management');
            if (_index1 !== -1) {
                if (!this.selectedOrgUserId && (this.global.getVal('loggedOrgType') === AppEnum.customerType.MO || this.global.getVal('loggedOrgType') === AppEnum.customerType.ADV)) {
                    this.navItems = JSON.parse(this.global.getVal('menuCombination'));
                    this.disable(obj[_index1]);
                    this.navItems = obj;
                }
            }
        } else {
            this.navItems = JSON.parse(this.global.getVal('menuCombination'));
        }
        const _indexResource = obj.findIndex((m) => m.name === 'Resources');
        if (_indexResource > 0) {
            this.disableCropProphetWebsite(obj[_indexResource]);
            this.navItems = obj;
        }

        setTimeout(() => {
            if (this.global.getVal('elevator') > 0) {
                $('a[href="#/resources/CashGrainQuotes"]').removeClass('hide');
            } else {
                $('a[href="#/resources/CashGrainQuotes"]').addClass('hide');
            }

            if (
                this.global.getVal('loggedInUserEmail') !== 'arati@agyield.com' &&
                this.global.getVal('loggedInUserEmail') !== 'arati_t@agyield.com'
            ) {
                $('a[href="#/alert"]').addClass('hide');
            }
        }, 1000);

          if (
              this.global.getVal('loggedOrgType') === AppEnum.customerType.MO ||
              this.global.getVal('loggedOrgType') === AppEnum.customerType.ADV
          ) {
              if (this.global.getVal('isMoMasterChecked').toString() === AppEnum.isMoMasterChecked.true) {
                  this.disableMenuItemByName("Dashboard");
                  this.disableMenuItemByName("Management");
                  this.disableMenuItemByName("Hedge Recommendations");
                  this.disableMenuItemByName("Athena");
                  this.disableMenuItemByName("Video/Audio Commentary");
                  // $('a[href="#/dashboard"]').eq(1).addClass('hide');
                  // $('a:contains("Management")').addClass('hide');
                  $('a[href="#/agyield-mbp"]').addClass('hide');
                  $('a[href="#/athena"]').addClass('hide');
                  $('#userDropDown').attr('disabled', 'disabled');
                  $('a[href="#/resources/HedgeRecommendations"]').addClass('hide');
                  $('a[href="#/resources/HedgeReportsAudio"]').addClass('hide');
              }
          }
        
        this.commonService.navItems = this.navItems;
        if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
            if (
                window.location.hash !== '#/portfolio-questionnaire' &&
                !(this._router.url.includes('resources/HedgeRecommendations') || this._router.url.includes('HedgeReportsAudio'))
            ) {
                this._router.navigate(['/agyield-mbp']);
            }
        }

        if(this.global.getVal('isLivestock') && this.selectedStockType == 'livestock') {
            this.disableMenuItemByName("Hedges Snapshot");
            this.disableMenuItemByName("Performance Chart");
            this.disableMenuItemByName("Profitability Outlook");
            this.disableMenuItemByName("Athena");
            this.disableMenuItemByName("Benchmarking");
            this.disableMenuItemByName("AgYield MBP");
            this.disableMenuItemByName("Crops");
            this.disableMenuItemByName("Fields");
            //this.disableMenuItemByName("Insurance");
            //this.disableMenuItemByName("Transactions");
            this.disableMenuItemByURL("/expense/crop");
            this.disableMenuItemByURL("/insurance");
            this.disableMenuItemByURL("/account-summary");
            this.disableMenuItemByURL("/transactions");
            this.disableMenuItemByURL("/dashboard");

            
        } else {
            this.disableMenuItemByName("Pens");
            this.disableMenuItemByURL("/pen-expense");
            //this.disableMenuItemByName("Pen Insurance");
            //this.disableMenuItemByName("Pen Transactions");
            this.disableMenuItemByURL("/pen-account-summary");
            this.disableMenuItemByURL("/pen-insurance");
            this.disableMenuItemByURL("/pen-transactions");
            this.disableMenuItemByURL("/pen-dashboard");
            
        }
        // Top search account is free trail but loggedIn user is not free trail account
        if(this.global.getVal('isSelectedFreeTrialAccount') == true && (this.global.getVal('isFreeTrialAccount') == false || this.global.getVal('isFreeTrialAccount') == null)){
            this.disableMenuItemByName("Entities");
            this.disableMenuItemByName("Fields");
        }

        // Disable the Hedge Recommendations For PLAN_TYPE_AGYIELD_PLUS,PLAN_TYPE_ENTERPRISE_LITE
        const userPlanId = this.global.getVal('userPlanId');
        const planId = this.global.getVal('planId');
        
        if (this.disableHedgeRecomPlanTypes.includes(userPlanId) || this.disableHedgeRecomPlanTypes.includes(planId)) {
            this.disableMenuItemByName("Hedge Recommendations");
            $('a[href="#/resources/HedgeRecommendations"]').addClass('hide');
        }

    }
    disableMenuItemByURL(url: string) {        
        this.navItems = this.removeMenuObjectByURL(this.navItems, url);
    }
    removeMenuObjectByURL(arr: any[], url: string) {
        return arr.filter(item => {
            if (item.url.trim() === url) {
              return false;
            } else if (item.children && item.children.length > 0) {
                item.children = this.removeMenuObjectByURL(item.children, url);
                return true;
            }
            return true;
        });
      }
    disableMenuItemByName(name: string) {
        this.navItems = this.removeMenuObjectByName(this.navItems, name);
    }
    
    removeMenuObjectByName(arr: any[], name: string) {
      return arr.filter(item => {
          if (item.name.trim() === name) {
            return false;
          } else if (item.children && item.children.length > 0) {
              item.children = this.removeMenuObjectByName(item.children, name);
              return true;
          }
          return true;
      });
    }
    

    /**
     * Function to disable menu items
     *
     * @param obj
     * @returns
     */
    disable(obj: ayObject): ayObject {
        obj.attributes = {
            disabled: true
        };
        if (obj.children !== undefined && obj.children.length > 0) {
            for (let i = 0; i < obj.children.length; i++) {
                if (obj.children[i].name !== 'AgYield MBP') {
                    obj.children[i].attributes = {
                        disabled: true
                    };
                }
            }
        }
        return obj;
    }
    
    /**
     * Function to enable menu items
     *
     * @param obj
     * @returns
     */
    enable(obj: ayObject): ayObject {
        if(obj?.attributes) {
            delete obj.attributes;
        }
        if (obj.children !== undefined && obj.children.length > 0) {
            for (let i = 0; i < obj.children.length; i++) {
                if (obj.children[i].name !== 'AgYield MBP') {
                    if(obj.children[i]?.attributes) {
                        delete obj.children[i].attributes;
                    }
                }
            }
        }
        return obj;
    }

    /**
     * to minimize the toggle
     */
    toggleMinimize(e: boolean): void {
        this.sidebarMinimized = e;
    }

    getFullName() {
        const userFirstName = this.global.getVal('userFirstName');
        const userLastName = this.global.getVal('userLastName');
        const userFirstNameLength = userFirstName.length;
        const maxLastNameLength = AppConstants.USERFULLNAMELENGTH - 1 - userFirstNameLength;
    
        if (userFirstNameLength >= AppConstants.USERFULLNAMELENGTH) {
            let fullLength = AppConstants.USERFULLNAMELENGTH - userFirstNameLength;

            if(userFirstNameLength == AppConstants.USERFULLNAMELENGTH){
                return userFirstName;
            }
            
            let modifiedUsername = userFirstName.slice(0, fullLength);
            return modifiedUsername + '..';
        }
    
        const lastName = this.truncateName(userLastName, maxLastNameLength);
    
        return maxLastNameLength > 0 ? `${userFirstName} ${lastName}` : userFirstName;
    }
    
    truncateName(name:any, maxLength:any) {
        if (maxLength <= 0) return '';
        return name.length > maxLength ? `${name.substring(0, maxLength)}..` : name;
    }

    ngOnInit(): void {
        this.global.setVal('isMobile', Util.isMobile());
        this.global.setVal('isIPad', Util.isIpad());
        this.global.setVal('isLogout', 0);
        this.global.setVal('isFreeTrial', false);
        if (this.reload) {
            this.reload = false;
            this.getUserInfo();
        }
        this.tabId =
            sessionStorage.tabID && sessionStorage.closedLastTab !== AppEnum.closedLastTab.two
                ? sessionStorage.tabID
                : (sessionStorage.tabID = Math.random());
        sessionStorage.closedLastTab = AppEnum.closedLastTab.two;
        // TODO: Review this changes (Version upgrade)
        // $(window).on('unload beforeunload', () => {
        //     sessionStorage.closedLastTab = AppEnum.closedLastTab.one;
        // });
    }

    ngOnDestroy(): void {
        // this.navigationSubscriber.unsubscribe();
        this.commonService.onYearChange.unsubscribe();
        this.commonService.menuChange.unsubscribe();
    }

    getImageUrl(userId: number): void {
        const param = {
            'idArr[]': userId
        };
        // Call the service to call the API service and get the data from server
        this.service
            .callGetApi(ApiUrlConfig.Header.imageUrl, param)
            .then((response: imageUrl) => {
                if (response[userId] !== '') {
                    this.image = response[userId];
                }
            })
            .catch((error: HttpErrorResponse) => {
                // Caller can handle via status or they can emit the event to handle at common level
                this.commonService.commonErrorEvent.emit(error);
            });
    }

    /**
     * to get logged in user information and stored in global variable
     */
    getUserInfo(): void {
        const param = {
            id: this._webStorageService.get('userId'),
            getPermissions: true,
            getDepartments: true
        };

        // Call the service to call the API service and get the data from server
        this.service
            .callGetApi(ApiUrlConfig.Header.userInfo, param)
            .then((response: UserDetail) => {
                if ((response.userRole.roleCode == 'ADM' || response.userRole.roleCode == 'STF')  && response.isLivestock == 0) {
                    this.isPenDataExist();
                }
                this.setGlobals(response);
                this.setDataForBackBone(response);

                this.getActiveCrops(response.selectedYear);
                this.getNotification();

                this.getImageUrl(response.id);
                this.getActiveYear();
            })
            .catch((error: HttpErrorResponse) => {
                // Caller can handle via status or they can emit the event to handle at common level
                this.commonService.commonErrorEvent.emit(error);
            });
    }

    /**
     * this function is used to make service call to get all customers / users
     *
     * @param searchVal
     */
    searchUser(event: ayObject): void {
        // api service call to get users based on searched string where character should be greater than 2
        if (event.length > 2) {
            Util.showLoading();

            const param = {
                SEARCHPARAM: event
            };

            // Call the service to call the API service and get the data from server
            this.service
                .callPostApi(ApiUrlConfig.Header.searchUser, param)
                .then((response: ayObject) => {
                    Util.hideLoading();

                    // comment
                    this.searchUserData = Object.keys(response).map((key) => ({
                        id: response[key].id,
                        name: response[key].name,
                        sup: response[key].isSuspended,
                        isSponsor: response[key].isSponsor
                    }));

                    this.showSearchbox = true;
                })
                .catch((error: HttpErrorResponse) => {
                    Util.hideLoading();

                    // Caller can handle via status or they can emit the event to handle at common level
                    this.commonService.commonErrorEvent.emit(error);
                });
        } else if (event.length === 0) {
            Util.hideLoading();

            this.searchUserData = [];
            this.showSearchbox = false;
        }
    }

    /**
     * this function is used to toggle searchbox show/hide
     *
     * @param val
     */
    showHideSearchBox(val: number = 0): void {
        if (val === 1 && this.searchUserData.length > 0) {
            this.showSearchbox = true;
            setTimeout(() => {
                // this will make the execution after the above boolean has changed
                this.searchElement.nativeElement.focus();
            }, 1000);
        } else {
            this.showSearchbox = false;
        }
    }

    /**
     * this function is used to set globals
     *
     * @param response
     */
    setGlobals(response: ayObject): void {
        if (
            response.userRole.roleCode === AppEnum.roleCode.ADM ||
            response.userRole.roleCode === AppEnum.roleCode.STF ||
            response.userRole.roleCode === AppEnum.roleCode.SLS
        ) {
            this.showUserDropdown = true;
        }
        if (
            (response.organization.usertype === AppEnum.customerType.MO || response.organization.usertype === AppEnum.customerType.ADV) &&
            (response.userRole.roleCode === AppEnum.roleCode.OAD || response.userRole.roleCode === AppEnum.roleCode.OUS)
        ) {
            this.showUserDropdown = true;
        }

        if (response.loggedCustName) {
            this.selectedOrgUser = response.loggedCustName;
        }
        if (response.loggedCustId) {
            this.selectedOrgUserId = response.loggedCustId;
        }
        if (response.organization.orgType === AppEnum.customerType.ADV && response.organization.customerType === AppEnum.customerType.MO) {
            this.global.setVal('loggedOrgType', AppEnum.customerType.ADV);
        }
        if (response.organization.orgType === AppEnum.orgType.TNT && response.organization.customerType === AppEnum.customerType.MO) {
            this.global.setVal('loggedOrgType', AppEnum.customerType.MO);
        }

        this.global.setVal('isMoMasterChecked', response.moMasterChecked);
        this.global.setVal('moLandUnit', response.organization.landUnit);
        this.global.setVal('moCurrencyUnit', response.organization.currencyUnit);
        this.global.setVal('isMoMasterAccount', response.organization.isMoMasterAccount);

        if (response.organization.isMoMasterAccount === false || response.organization.isMoMasterAccount === null) {
            this.global.setVal('isMoMasterChecked', 0);
        }
        if (this.global.getVal('isMoMasterChecked') == 1) {
            this.showUserDropdown = false;
        }
        if (response.loggedOrgType === AppEnum.customerType.MO || response.loggedOrgType === AppEnum.customerType.ADV) {
            if (response.loggedCustId === false) {
                this.global.setVal('isMoAdmin', 0);
                this.global.setVal('isMoMasterChecked', 0);
                response.cropCount = -1;
                response.penCount = -1;
            } else {
                this.global.setVal('isMoAdmin', 1);
                this.global.setVal('isMoMasterChecked', 1);
                this.global.setVal('moLandUnit', response.loggedLandUnit);
                this.global.setVal('moCurrencyUnit', response.loggedCurrencyUnit);
                response.cropCount = -1;
                response.penCount = -1;
            }
        } else {
            this.global.setVal('isMoAdmin', 0);
        }
        let yearSelect = response.selectedYear;
        if (yearSelect === '' || yearSelect === null) {
            yearSelect = new Date().getFullYear();
        }
        const selectedYearId = this._webStorageService.get(this.tabId) ? this._webStorageService.get(this.tabId) : yearSelect;
        this._webStorageService.set(this.tabId, selectedYearId);
        this.selectedYear = selectedYearId;
        this.selectedStockType = response.selectedStockType
        
        this.global.setVal('loggedInUser', response);
        this.global.setVal('loggedUserId', response.loggedUserId);
        this.global.setVal('loggedCustId', response.loggedCustId);
        this.global.setVal('loggedCustName', response.loggedCustName);
        this.global.setVal('isSuspended', response.isSuspended);
        this.global.setVal('loggedOrgId', response.loggedOrgId);
        this.global.setVal('userFirstName', response.firstName.toUpperCase());
        this.global.setVal('userLastName', response.lastName.toUpperCase());
        this.global.setVal('userLoginId', response.email);
        this.global.setVal('userRole', response.userRole.roleCode);
        this.global.setVal('userOrgId', response.organization.id);
        this.global.setVal('elevator', response.elevator);
        this.global.setVal('loggedCustElevId', response.loggedCustElevId);
        this.global.setVal('orgType', response.organization.usertype);
        this.global.setVal('customerType', response.organization.customerType);
        this.global.setVal('tabAccess', response.organization.plan.tabAccess);
        this.global.setVal('planName', response.organization.plan.name);
        this.global.setVal('planId', response.organization.plan.id);
        this.global.setVal('loggedInUserEmail', response.email);
        this.global.setVal('selectedYear', selectedYearId);
        this.global.setVal('orgTypeCode', response.organization.orgType);
        this.global.setVal('logged_organization_id', response.logged_organization_id);
        this.global.setVal('touStatus', response.touStatus);
        this.global.setVal('status', response.status);
        this.global.setVal('currencyUnit', response.loggedCurrencyUnit);
        this.global.setVal('landUnit', response.loggedLandUnit);
        this.global.setVal('userPlanId', response.loggedPlanId);
        this.global.setVal('isPool', response.isPool);
        this.global.setVal('landUnitName', response.loggedLandUnit === 'ac' ? AppEnum.landUnitName.Acres : AppEnum.landUnitName.Hectares);
        this.global.setVal(
            'landUnitNameSingular',
            response.loggedLandUnit === 'ac' ? AppEnum.landUnitNameSingular.Acre : AppEnum.landUnitNameSingular.Hectare
        );
        
        if(response.organization.usertype == "MO" && response.userRole.roleCode == "OAD" && response.apiAccess == true){
            this.showSettingsMenu = true;
            this.global.setVal('isValidApiRequest', true);
        }else{
            this.global.setVal('isValidApiRequest', false);
        }
        this.global.setVal('changeCrop', 1);
        this.global.setVal('basicUserAcres', response.basicUserAcres);
        this.global.setVal('cropCount', response.cropCount);
        this.global.setVal('penCount', response.penCount);
        this.global.setVal('isExpiredPool', response.isExpiredPool);
        this.global.setVal('isSponsor', response.isSponsor);
        this.global.setVal('haveCustomerAccess', response.haveCustomerAccess);
        if (this.global.getVal('isMoMasterChecked') === 1) {
            this.global.setVal('currencyUnit', this.global.getVal('moCurrencyUnit'));
            this.global.setVal('landUnit', this.global.getVal('moLandUnit'));
            this.global.setVal(
                'landUnitName',
                this.global.getVal('moLandUnit') === 'ac' ? AppEnum.landUnitName.Acres : AppEnum.landUnitName.Hectares
            );
        }
        if (
            this.global.getVal('userPlanId') === AppConstants.PLAN_TYPE_BASIC_USER ||
            this.global.getVal('planId') === AppConstants.PLAN_TYPE_BASIC_USER
        ) {
            this.global.setVal('isBasicUser', 1);
        } else {
            this.global.setVal('isBasicUser', 0);
        }
        this.global.setVal('expMgmtType', 0);
        this.global.setVal('chkMBPCrop', 0);
        this.isUserLoaded = true;
        this.global.setVal('isCropProphet', response.isCropProphet);
        this.global.setVal('selectedOrgType', response.loggedOrgType);
        this.global.setVal('isAdvisor', response.organization.orgTypeCode);
        this.global.setVal('isFreeTrialAccount', response.isFreeTrial);
        this.global.setVal('isSelectedFreeTrialAccount', response.loggedIsFreeTrial);

        this.global.setVal('freeTrialDuration', response.freeTrialDuration);
        this.global.setVal('isLivestock', response.isLivestock);
        this.global.setVal('selectedStockType', (response.isLivestock)? response.selectedStockType : 'grain');
        if (this.global.getVal('isMoMasterChecked') == 1) {
            this.global.setVal('selectedStockType', 'grain');
        }
    }

    setDataForBackBone(responseText: UserDetail): void {
        app.loggedInUser = responseText;
        app.loggedInUser.userType = AppEnum.userType.SO;
        if (responseText.loggedOrgType === AppEnum.customerType.MO || responseText.loggedOrgType === AppEnum.customerType.ADV) {
            app.isMoAdmin = 1;
            app.isMoMasterChecked = 1;
            responseText.cropCount = -1;
        } else {
            app.isMoAdmin = 0;
        }

        app.userId = responseText.id;
        app.loggedUserId = responseText.loggedUserId;
        app.loggedCustId = responseText.loggedCustId;
        app.loggedCustName = responseText.loggedCustName;
        app.previousLoggedCustId = app.loggedCustId;
        app.previousLoggedCustName = app.loggedCustName;
        app.isSuspended = responseText.isSuspended;
        app.loggedOrgId = responseText.loggedOrgId;
        app.userFirstName = responseText.firstName;
        app.userLastName = responseText.lastName;
        app.userLoginId = responseText.email;
        app.userRole = responseText.userRole;
        app.userOrgId = responseText.organization.id;
        app.elevator = responseText.elevator;
        app.loggedCustElevId = responseText.loggedCustElevId;
        app.orgType = responseText.organization.usertype;
        app.actualOrgType = responseText.organization.usertype;
        app.resources.CashGrainQuotes.url = app.resources.CashGrainQuotes.url + responseText.organization.id;
        app.customerType = responseText.organization.customerType;
        app.tabAccess = responseText.organization.plan.tabAccess;
        app.isMoMasterChecked = responseText.moMasterChecked;
        app.planName = responseText.organization.plan.name;
        app.planId = responseText.organization.plan.id;
        app.isSponsor = responseText.isSponsor;
        let yearSelect = responseText.selectedYear;
        app.isLivestock = this.global.getVal('isLivestock');
        if (yearSelect === null) {
            yearSelect = new Date().getFullYear();
        }

        app.selectedYear = this._webStorageService.get(this.tabId) ? this._webStorageService.get(this.tabId) : yearSelect;
        this._webStorageService.set(this.tabId, app.selectedYear);
        // app.selectedYear = this._webStorageService.get(this.tabId);
        app.orgTypeCode = responseText.organization.orgType;
        app.loggedOrgType = responseText.loggedOrgType;
        app.logged_organization_id = responseText.logged_organization_id;
        app.touStatus = responseText.touStatus;
        app.status = responseText.status;
        app.serverEnv = responseText.serverEnv;
        app.currencyUnit = responseText.loggedCurrencyUnit;
        app.landUnit = responseText.loggedLandUnit;
        app.moLandUnit = responseText.organization.landUnit;
        app.moCurrencyUnit = responseText.organization.currencyUnit;
        app.isMoMasterAccount = responseText.organization.isMoMasterAccount;
        app.expMgmtType = 0;
        app.chkMBPCrop = 0;

        if (responseText.organization.isMoMasterAccount === false) {
            app.isMoMasterChecked = 0;
        }

        if (app.isMoMasterChecked == null) {
            app.isMoMasterChecked = 0;
        }

        if (app.isMoMasterChecked === 1) {
            app.currencyUnit = app.moCurrencyUnit;
            app.landUnit = app.moLandUnit;
            app.landUnitName = app.moLandUnit === 'ac' ? AppEnum.landUnitName.Acres : AppEnum.landUnitName.Hectares;
        }

        app.userPlanId = responseText.loggedPlanId;
        app.isPool = responseText.isPool;
        app.landUnitName = responseText.loggedLandUnit === 'ac' ? AppEnum.landUnitName.Acres : AppEnum.landUnitName.Hectares;
        app.changeCrop = 1;
        app.basicPlanAcres = responseText.basicUserAcres;
        app.isExpiredPool = responseText.isExpiredPool;
        app.isIe = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
        app.appUrl = environment.applicationHost;

        if (responseText.loggedOrgType === AppEnum.customerType.MO || responseText.loggedOrgType === AppEnum.customerType.ADV) {
            if (Boolean(responseText.loggedCustId) === false) {
                app.isMoAdmin = 0;
                app.isMoMasterChecked = 0;
                responseText.cropCount = -1;
            } else {
                app.isMoAdmin = 1;
                app.isMoMasterChecked = 1;
                responseText.cropCount = -1;
            }
            app.orgType = responseText.loggedOrgType;
        } else {
            app.isMoAdmin = 0;
        }

        app.cropCount = responseText.cropCount;
        this.isFreeTrial = this.global.app.isFreeTrialAccount;
    }

    /**
     * this function is to get active years
     */
    getActiveYear(): void {
        // Call the service to call the API service and get the data from server
        let yearApiUrl = ApiUrlConfig.Header.activeYears;
        if(this.global.getVal('isLivestock') && this.selectedStockType == 'livestock') {
            yearApiUrl = ApiUrlConfig.Header.activeLivestockYears;
        }
        this.service
            .callGetApi(yearApiUrl, {})
            .then((response: YearList[]) => {
                this.activeYears = response;
                this.showHeader = true;
                this.global.setVal('activeYears', this.activeYears);
                this.commonService.activeYears.emit(this.activeYears);
            })
            .catch((error: HttpErrorResponse) => {
                // Caller can handle via status or they can emit the event to handle at common level
                this.commonService.commonErrorEvent.emit(error);
            });
    }

    /**
     * this function is to get active crops
     *
     * @param year
     */
    getActiveCrops(year: number, isYear: number = 0): void {
        const selectedYearId = year ? year : new Date().getFullYear();
        Util.showLoading();

        const param = {
            year: selectedYearId
        };

        // Call the service to call the API service and get the data from server
        this.service
            .callGetApi(ApiUrlConfig.Header.activeCrops, param)
            .then((response: Crop[]) => {
                app.cropCount = response.length;
                //if(this.selectedStockType !== 'livestock') {
                    this.global.setVal('cropCount', response.length);
                //} else {
                if(this.selectedStockType === 'livestock') {
                    this.service
                    .callPostApi(ApiUrlConfig.Pen.PenList, param)
                    .then((response: any) => {
                        this.global.setVal('penCount', ((response.list.length > 0)? 1 : 0) );
                        this.commonService.menuChange.emit(this.global.getVal('menuCombination'));
                    })    
                }
                if (isYear === 1) {
                    this.getPoolCount(selectedYearId);
                }
                this.menuChanges();

                // Set the first menu option as home link
                this.setHomeLink();

                // in case page is reloaded check if the path is allowed or not
                const isPathAllowed = this.isPathAllowed();

                // Redirect page to appropriate page first time or a not allowed pathe is hit
                // or customer change
                if (this._webStorageService.get('is_first_time') || !this._webStorageService.get('is_year_change') || !isPathAllowed) {
                    this.redirect();
                    this.resetWebStorage();
                }

                if (this._webStorageService.get('is_year_change')) {
                    this._webStorageService.set('is_year_change', false);
                }

                Util.hideLoading();
            })
            .catch((error: HttpErrorResponse) => {
                Util.hideLoading();
                this.logout();
                // Caller can handle via status or they can emit the event to handle at common level
                this.commonService.commonErrorEvent.emit(error);
            });
    }

    /**
     * this function is to get active pool account count
     */
    getPoolCount(year: number): void {
        const selectedYearId = year ? year : new Date().getFullYear();
        Util.showLoading();

        const param = {
            year: selectedYearId
        };

        // Call the service to call the API service and get the data from server
        this.service
            .callGetApi(ApiUrlConfig.Header.getPoolCount, param)
            .then((response: PoolAccount[]) => {
                this.global.setVal('isPool', response);
                this.menuChanges();
                // Set the first menu option as home link
                this.setHomeLink();
                Util.hideLoading();
            })
            .catch((error: HttpErrorResponse) => {
                Util.hideLoading();
                // Caller can handle via status or they can emit the event to handle at common level
                this.commonService.commonErrorEvent.emit(error);
            });
    }

    /**
     * this function is to get active notifications
     */
    getNotification(): void {
        Util.showLoading();

        // Call the service to call the API service and get the data from server
        this.service
            .callGetApi(ApiUrlConfig.Header.notification, {})
            .then((response: Notification[]) => {
                this.notificationArr = response;

                Util.hideLoading();
            })
            .catch((error: HttpErrorResponse) => {
                Util.hideLoading();

                // Caller can handle via status or they can emit the event to handle at common level
                this.commonService.commonErrorEvent.emit(error);
            });
    }

    setUser(year: number): void {
        Util.showLoading();

        const param = {
            year
        };

        if (app.isMoMasterChecked.toString() === AppEnum.isMoMasterChecked.true || app.isMoAdmin.toString() === AppEnum.isMoAdmin.true) {
            this.service
                .callGetApi(ApiUrlConfig.Header.getMoCropCount, param)
                .then((response: number) => {
                    app.moCropCount = response;
                    Util.hideLoading();
                    if (response <= 0) {
                        const alertMessage = new AlertMessage();
                        alertMessage.type = AlertMessage.Type.warning;
                        alertMessage.text = 'There is no crop in selected year .';
                        alertMessage.title = 'Oops!!';
                        $('#year_angular').val(app.selectedYear);
                        Util.showTextMessage(alertMessage);
                        return;
                    } else {
                        this._webStorageService.set('selected_year', year);
                        this._webStorageService.set(this.tabId, year);
                        this.setYearData(year);
                    }
                })
                .catch((error: HttpErrorResponse) => {
                    // Caller can handle via status or they can emit the event to handle at common level
                    this.commonService.commonErrorEvent.emit(error);
                });
        } else {
            this._webStorageService.set('selected_year', year);
            this._webStorageService.set(this.tabId, year);
            this.setYearData(year);
        }
    }

    setYearData(year: number): void {
        const param = {
            year
        };
        // Call the service to call the API service and get the data from server
        this.service
            .callPostApi(ApiUrlConfig.Header.setUser, param)
            .then(() => {
                Util.hideLoading();
                this.selectedYear = year.toString();
                this.global.setVal('selectedYear', year);

                if (this._router.url.includes('crops/entry') && !this._router.url.includes('crops/entry/0')) {
                    this.redirectOnYearChange(year, '/crops');
                } else if (this._router.url.includes('pens/entry') && !this._router.url.includes('pens/entry/0')) {
                    this.redirectOnYearChange(year, '/pens');
                } else if (this._router.url.includes('pen-expense/detail')) {
                    this.redirectOnYearChange(year, '/pen-expense');
                } else if (this._router.url.includes('pen-insurance/entry')) {
                    this.redirectOnYearChange(year, '/pen-insurance');    
                } else if (this._router.url.includes('expense/crop/detail')) {
                    this.redirectOnYearChange(year, '/expense/crop');
                } else if (this._router.url.includes('insurance/entry')) {
                    this.redirectOnYearChange(year, '/insurance');
                } else if (this._router.url.includes('field/entry') || this._router.url.includes('field-clu/')) {
                    this.redirectOnYearChange(year, '/field');
                } else if (
                    this._router.url.includes('-transaction/') ||
                    this._router.url.includes('transaction-future/entry') || this._router.url.includes('pen-transaction-future/entry') || this._router.url.includes('transaction-option/entry') ||
                    this._router.url.includes('pen-transaction-option/entry')
                ) {
                    this._webStorageService.set('is_year_change', false);

                    if(this.global.getVal('isLivestock') && this.selectedStockType == 'livestock'){
                        this._router.navigate(['/pen-transactions']);
                    } 
                    else {
                        this._router.navigate(['/transactions']);
                    }       
                    this.getActiveCrops(year, 1);
                } else {
                    this._webStorageService.set('is_year_change', true);
                    window.location.reload();
                }
            })
            .catch((error: HttpErrorResponse) => {
                Util.showLoading();

                // Caller can handle via status or they can emit the event to handle at common level
                this.commonService.commonErrorEvent.emit(error);
            });
    }

    redirectOnYearChange(year: number, url: string): void {
        this._webStorageService.set('is_year_change', false);
        this._router.navigate([url]);
        this.getActiveCrops(year, 1);
    }
    /**
     * this function will be called when logout
     */
    logout(): void {
        Util.showLoading();

        this.service
            .callPostApi(ApiUrlConfig.Header.logout, {})
            .then((response: Logout) => {
                // Handle the success response
                if (
                    response &&
                    response.code &&
                    response.status &&
                    Number(response.code) === 7000 &&
                    response.status === AppEnum.responseStatus.destroyed
                ) {
                    this.clearAllData(response.code);
                } else {
                    this.clearAllData();
                }
            })
            .catch((error: HttpErrorResponse) => {
                // Caller can handle via status or they can emit the event to handle at common level
                this.commonService.commonErrorEvent.emit(error);
            });
    }

    /**
     * this function will be called when logout and clear all data
     */
    clearAllData(errorCode: number = 0): void {
        this._webStorageService.remove('oauth_token');
        this._webStorageService.remove('oauth_verifier');
        this._webStorageService.remove('userId');
        this._webStorageService.remove('key');
        this._webStorageService.remove('oauth_token_secret');
        this._webStorageService.remove('csecret');
        this._webStorageService.remove(this.tabId);
        window.localStorage.setItem('change_customer', '');
        this._webStorageService.remove('change_customer');
        Util.hideLoading();
        if (Number(errorCode) === 7000) {
            const confirmMessage = new ConfirmationMessage();
            confirmMessage.type = AlertMessage.Type.warning;
            confirmMessage.title = 'Oops!!';
            confirmMessage.isShowCancelButton = false;
            confirmMessage.text = 'Session has expired please login again.';
            confirmMessage.confirmButtonText = 'Ok';
            confirmMessage.okCallBack = async () => {
                location.reload();
            };
            confirmMessage.cancelCallBack = async () => {
                location.reload();
            };
            Util.showConfirmationDialog(confirmMessage);
        } else {
            location.reload();
        }
    }

    /**
     * this function will be called when any user will be selected from the search box
     *
     * @param id
     * @param event
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    switchCustomer(event: any): void {
        // comment
        if (event.id) {
            const data = this.searchUserData.filter((obj) => obj.id === event.id);

            if (data.length > 0) {
                this.selectedOrgUser = data[0].name;
                this.selectedOrgUserId = data[0].id;
                const params = {
                    org_id: data[0].id,
                    cust_name: data[0].name,
                    year: this.selectedYear
                };

                window.localStorage.setItem('change_customer', this.selectedOrgUserId.toString());

                // Call the service to call the API service and get the data from server
                this.service
                    .callPostApi(ApiUrlConfig.Header.switchCustomer, params)
                    .then((response: SwitchCustomer) => {
                        this._webStorageService.set('is_customer_change', true);
                        if (response.customerInfo.isMoMasterAccount === false) {
                            app.isMoMasterChecked = 0;
                            app.isMoAdmin = 0;
                            this.global.setVal('isMoMasterChecked', AppEnum.isMoMasterChecked.false);
                            this.global.setVal('isMoAdmin', 0);
                        } else {
                            app.isMoAdmin = 1;
                            app.isMoMasterChecked = 1;
                            this.global.setVal('isMoMasterChecked', AppEnum.isMoMasterChecked.true);
                            this.global.setVal('isMoAdmin', 1);
                        }
                        if (app.isMoAdmin.toString() === 1) {
                            app.orgType = response.customerInfo.orgType;
                            this.global.setVal('orgType', response.customerInfo.orgType);
                        } else {
                            app.orgType = app.actualOrgType;
                            this.global.setVal('orgType', app.actualOrgType);
                        }

                        if (app.isMoMasterChecked.toString() === AppEnum.isMoMasterChecked.true && response.customerInfo.crop <= 0) {
                            const alertMessage = new AlertMessage();
                            alertMessage.type = AlertMessage.Type.warning;
                            alertMessage.text = 'There is no crop in selected year .';
                            alertMessage.title = 'Oops!!';
                            Util.showTextMessage(alertMessage);
                            const param = {
                                id: app.previousLoggedCustId
                            };
                            this.searchUserData.push({
                                id: app.previousLoggedCustId,
                                name: app.previousLoggedCustName,
                                sup: 'ACT',
                                isSponsor: app.previousLoggedCustsSponsor
                            });
                            this.switchCustomer(param);
                            this.searchUserData = _.uniq(this.searchUserData, 'id');
                            return;
                        }
                        this.global.setVal('selectedUserEmail', response.customerInfo.email);
                        this.global.setVal('isSuspended', '');
                        this.global.setVal('valuesIn', '');
                        this.global.setVal('ADvaluesIn', '');
                        this.global.setVal('currencyUnit', response.customerInfo.currencyUnit);
                        this.global.setVal('landUnit', response.customerInfo.landUnit);
                        this.global.setVal('userPlanId', response.customerInfo.planId);
                        this.global.setVal('isPool', response.customerInfo.isPool);
                        this.global.setVal('elevator', response.customerInfo.elevator);
                        this.global.setVal('selectedOrgType', response.customerInfo.orgType);
                        this.global.setVal('isAdvisor', response.customerInfo.orgType);
                        this.global.setVal(
                            'landUnitName',
                            response.customerInfo.landUnit === 'ac' ? AppEnum.landUnitName.Acres : AppEnum.landUnitName.Hectares
                        );
                        this.global.setVal('cropCount', response.customerInfo.crop);
                        this.global.setVal('loggedCustName', data[0].name);
                        this.global.setVal('loggedCustId', data[0].id);
                        this.global.setVal('chkMBPCrop', 0);
                        if (
                            this.global.getVal('userPlanId') === AppConstants.PLAN_TYPE_BASIC_USER ||
                            this.global.getVal('planId') === AppConstants.PLAN_TYPE_BASIC_USER
                        ) {
                            this.global.setVal('isBasicUser', 1);
                        } else {
                            this.global.setVal('isBasicUser', 0);
                        }

                        if (this.global.getVal('isMoMasterChecked').toString() === AppEnum.isMoMasterChecked.true) {
                            app.previousLoggedCustName = data[0].name;
                            app.previousLoggedCustId = data[0].id;
                            app.previousLoggedCustIsSponsor = data[0].isSponsor;
                        } else {
                            app.loggedCustName = data[0].name;
                            app.loggedCustId = data[0].id;
                            app.loggedCustIsSponsor = data[0].isSponsor;
                        }

                        app.isSuspended = '';
                        app.ADvaluesIn = '';
                        app.valuesIn = '';
                        app.currencyUnit = response.customerInfo.currencyUnit;
                        app.landUnit = response.customerInfo.landUnit;
                        app.userPlanId = response.customerInfo.planId;
                        app.isPool = response.customerInfo.isPool;
                        app.landUnitName =
                            response.customerInfo.landUnit === 'ac' ? AppEnum.landUnitName.Acres : AppEnum.landUnitName.Hectares;
                        app.cropCount = response.customerInfo.crop;

                        // Emit the customer switched event
                        this.commonService.customerSwitchedEvent.emit({
                            loggedCustId: data[0].id,
                            loggedCustName: data[0].name
                        });
                        
                        this.getNotification();
                        this.menuChanges();
                        // Set the first menu option as home link
                        this.setHomeLink();
                        this._webStorageService.set('is_year_change', true);
                        this.redirect(true);
                    })
                    .catch(() => {
                        // Caller can handle via status or they can emit the event to handle at common level
                        window.location.reload();
                    });
            }
        }
    }

    menuChanges(): void {
        if (this.global.getVal('status') === 'SUP' && this.global.getVal('isPool') > 0) {
            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.supPlan));
            this.commonService.menuChange.emit(menuList.supPlan);
            return;
        }
        if (this.global.getVal('userRole') === AppConstants.USER_AY_SALES) {
            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.mbpOus));
            this.commonService.menuChange.emit(menuList.mbpOus);
            return;
        }
        /* For Admin User */
        if (this.global.getVal('userRole') === AppConstants.USER_AY_ADMIN) {
            if (this.global.getVal('isMoMasterChecked').toString() === AppEnum.isMoMasterChecked.true) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.admMoMaster));
                this.commonService.menuChange.emit(menuList.admMoMaster);
            }

            if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.admBasic));
                this.commonService.menuChange.emit(menuList.admBasic);
            }

            if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.admPro));
                this.commonService.menuChange.emit(menuList.admPro);
            }

            if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.admPlus));
                this.commonService.menuChange.emit(menuList.admPlus);
            }

            if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.admMbp));
                this.commonService.menuChange.emit(menuList.admMbp);
            }

            if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.admMbpMaster));
                this.commonService.menuChange.emit(menuList.admMbpMaster);
            }
        }

        /* For Staff User */
        if (this.global.getVal('userRole') === AppConstants.USER_AY_STAFF) {
            if (this.global.getVal('isMoMasterChecked').toString() === AppEnum.isMoMasterChecked.true) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.stfMoMaster));
                this.commonService.menuChange.emit(menuList.stfMoMaster);
            }

            if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.stfBasic));
                this.commonService.menuChange.emit(menuList.stfBasic);
            }

            if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.stfPro));
                this.commonService.menuChange.emit(menuList.stfPro);
            }

            if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.stfPlus));
                this.commonService.menuChange.emit(menuList.stfPlus);
            }

            if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.stfMbp));
                this.commonService.menuChange.emit(menuList.stfMbp);
            }

            if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.stfMbpMaster));
                this.commonService.menuChange.emit(menuList.stfMbpMaster);
            }
        }

        /* For End User with basic plan */
        if (
            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString() &&
            this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
        ) {
            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.basicOad));
            this.commonService.menuChange.emit(menuList.basicOad);
        }

        if (
            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString() &&
            this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
        ) {
            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.basicOus));
            this.commonService.menuChange.emit(menuList.basicOus);
        }

        /* For End User with pro plan */
        if (
            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString() &&
            this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
        ) {
            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.proOad));
            this.commonService.menuChange.emit(menuList.proOad);
        }

        if (
            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString() &&
            this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
        ) {
            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.proOus));
            this.commonService.menuChange.emit(menuList.proOus);
        }

        /* For End User with plus plan */
        if (
            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString() &&
            this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
        ) {
            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.plusOad));
            this.commonService.menuChange.emit(menuList.plusOad);
        }

        if (
            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString() &&
            this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
        ) {
            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.plusOus));
            this.commonService.menuChange.emit(menuList.plusOus);
        }

        /* For End User with mbp plan */
        if (
            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString() &&
            this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
        ) {
            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.mbpOad));
            this.commonService.menuChange.emit(menuList.mbpOad);
        }

        if (
            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString() &&
            this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
        ) {
            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.mbpOus));
            this.commonService.menuChange.emit(menuList.mbpOus);
        }

        if (this.global.getVal('loggedOrgType') === AppEnum.customerType.MO) {
            if (
                this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
            ) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBP));
                this.commonService.menuChange.emit(menuList.moMBP);
            }

            if (
                this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
            ) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBP));
                this.commonService.menuChange.emit(menuList.moStfMBP);
            }

            if (
                [AppConstants.USER_OPERATION_ADMINISTRATOR, AppConstants.USER_OPERATION_STAFF_USER].indexOf(
                    this.global.getVal('userRole')
                ) > -1
            ) {
                if (this.global.getVal('isPool') === 0 && this.global.getVal('isSponsor')) {
                    if (
                        !this.global.getVal('haveCustomerAccess') &&
                        this.global.getVal('userPlanId').toString() !== AppConstants.PLAN_TYPE_MBP_MASTER.toString()
                    ) {
                        if (
                            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_PRO.toString() ||
                            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_LITE.toString()
                        ) {
                            if (this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR) {
                                this.global.setVal(
                                    'menuCombination',
                                    JSON.stringify(this.menuListClone.menuListClone.moSponsorMBPForMBpUserPlusProLite)
                                );
                                this.commonService.menuChange.emit(menuList.moSponsorMBPForMBpUserPlusProLite);
                            } else {
                                this.global.setVal(
                                    'menuCombination',
                                    JSON.stringify(this.menuListClone.menuListClone.moSponsorMBPForMBpUserPlusProLiteStaff)
                                );
                                this.commonService.menuChange.emit(menuList.moSponsorMBPForMBpUserPlusProLiteStaff);
                            }
                        } else {
                            /*  if (this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR) {
                                this.global.setVal(
                                    'menuCombination',
                                    JSON.stringify(this.menuListClone.menuListClone.moSponsorMBPForMBpUser)
                                );
                                this.commonService.menuChange.emit(menuList.moSponsorMBPForMBpUser);
                            } else {*/
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moSponsorMBPForMBpUser));
                            this.commonService.menuChange.emit(menuList.moSponsorMBPForMBpUser);
                            //  }
                        }
                        return;
                    } else if (!this.global.getVal('haveCustomerAccess')) {
                        if (
                            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_PRO.toString() ||
                            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_LITE.toString()
                        ) {
                            if (this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR) {
                                this.global.setVal(
                                    'menuCombination',
                                    JSON.stringify(this.menuListClone.menuListClone.moSponsorMBPPlusProLite)
                                );
                                this.commonService.menuChange.emit(menuList.moSponsorMBPPlusProLite);
                            } else {
                                this.global.setVal(
                                    'menuCombination',
                                    JSON.stringify(this.menuListClone.menuListClone.moSponsorMBPPlusProLiteMasterStaff)
                                );
                                this.commonService.menuChange.emit(menuList.moSponsorMBPPlusProLiteMasterStaff);
                            }
                        } else {
                            if (this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR) {
                                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moSponsorMBP));
                                this.commonService.menuChange.emit(menuList.moSponsorMBP);
                            } else {
                                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moSponsorMBPStaff));
                                this.commonService.menuChange.emit(menuList.moSponsorMBPStaff);
                            }
                        }
                        return;
                    } else if (
                        this.global.getVal('haveCustomerAccess') &&
                        this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString() &&
                        this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
                    ) {
                        this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moSponsorMBP));
                        this.commonService.menuChange.emit(menuList.moSponsorMBP);
                    }
                }
                if (
                    this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_PRO.toString() ||
                    this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_LITE.toString()
                ) {
                    if (this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR) {
                        if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBP));
                            this.commonService.menuChange.emit(menuList.moMBP);
                        } else if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                            /*
                             *If user login with MO and select Manage Bushel Program
                             */
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusLiteMbpMaster));
                            this.commonService.menuChange.emit(menuList.moPlusLiteMbpMaster);
                        } else if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                            /*
                             *If user login with MO and select Basic user
                             */
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProbasic));
                            this.commonService.menuChange.emit(menuList.moPlusProbasic);
                        } else {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProProUser));
                            this.commonService.menuChange.emit(menuList.moPlusProProUser);
                        }
                        return;
                    } else {
                        if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBPProStf));
                            this.commonService.menuChange.emit(menuList.moMBPProStf);
                        } else {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProProUserOus));
                            this.commonService.menuChange.emit(menuList.moPlusProProUserOus);
                        }
                        /*
                         *If user login with MO and select Manage Bushel Program
                         */
                        if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusLiteMbpMaster));
                            this.commonService.menuChange.emit(menuList.moPlusLiteMbpMaster);
                        }

                        /*
                         *If user login with MO and select Basic user
                         */

                        if (
                            this.global.getVal('userRole') !== AppConstants.USER_OPERATION_STAFF_USER &&
                            this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()
                        ) {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProbasic));
                            this.commonService.menuChange.emit(menuList.moPlusProbasic);
                        }
                        return;
                    }
                } else if (
                    this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PRO.toString() ||
                    this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_LITE.toString()
                ) {
                    if (this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR) {
                        if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBP));
                            this.commonService.menuChange.emit(menuList.moMBP);
                        } else {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProProUser));
                            this.commonService.menuChange.emit(menuList.moProProUser);
                        }
                        /*
                         *If user login with MO and select Manage Bushel Program
                         */

                        if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProMbpMaster));
                            this.commonService.menuChange.emit(menuList.moProMbpMaster);
                        }

                        /*
                         *If user login with MO and select Basic user
                         */

                        if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProbasic));
                            this.commonService.menuChange.emit(menuList.moProbasic);
                        }
                    } else {
                        if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBPProStf));
                            this.commonService.menuChange.emit(menuList.moMBPProStf);
                        } else {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProProUserOus));
                            this.commonService.menuChange.emit(menuList.moProProUserOus);
                        }
                        /*
                         *If user login with MO and select Manage Bushel Program
                         */
                        if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moLiteMbpMaster));
                            this.commonService.menuChange.emit(menuList.moLiteMbpMaster);
                        }

                        /*
                         *If user login with MO and select Basic user
                         */

                        if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                            this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProbasic));
                            this.commonService.menuChange.emit(menuList.moProbasic);
                        }
                    }
                } else if (
                    this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR ||
                    this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
                ) {
                    if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                        this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBPPro));
                        this.commonService.menuChange.emit(menuList.moMBPPro);
                    } else {
                        this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProbasic));
                        this.commonService.menuChange.emit(menuList.moProbasic);
                    }
                } else if (this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProProUser));
                    this.commonService.menuChange.emit(menuList.moProProUser);
                } else {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProProUserOus));
                    this.commonService.menuChange.emit(menuList.moProProUserOus);
                }
                return;
            }

            if (this.global.getVal('loggedCustId') === false && this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProProUserOus));
                this.commonService.menuChange.emit(menuList.moProProUserOus);
                return;
            }

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PRO.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProbasic));
                    this.commonService.menuChange.emit(menuList.moProbasic);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProProUser));
                    this.commonService.menuChange.emit(menuList.moProProUser);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProPlus));
                    this.commonService.menuChange.emit(menuList.moProPlus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProMbpMaster));
                    this.commonService.menuChange.emit(menuList.moProMbpMaster);
                }
            }

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_PRO.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProbasic));
                    this.commonService.menuChange.emit(menuList.moPlusProbasic);
                }

                if (
                    this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString() ||
                    this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_PRO.toString()
                ) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProProUser));
                    this.commonService.menuChange.emit(menuList.moPlusProProUser);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProPlus));
                    this.commonService.menuChange.emit(menuList.moPlusProPlus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProMbpMaster));
                    this.commonService.menuChange.emit(menuList.moPlusProMbpMaster);
                }
            }

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_LITE.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moLitebasic));
                    this.commonService.menuChange.emit(menuList.moLitebasic);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moLiteProUser));
                    this.commonService.menuChange.emit(menuList.moLiteProUser);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moLitePlus));
                    this.commonService.menuChange.emit(menuList.moLitePlus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moLiteMbpMaster));
                    this.commonService.menuChange.emit(menuList.moLiteMbpMaster);
                }
            }

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_LITE.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusLitebasic));
                    this.commonService.menuChange.emit(menuList.moPlusLitebasic);
                }

                if (
                    this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString() ||
                    this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_LITE.toString()
                ) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusLiteProUser));
                    this.commonService.menuChange.emit(menuList.moPlusLiteProUser);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPLusLitePlus));
                    this.commonService.menuChange.emit(menuList.moPLusLitePlus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusLiteMbpMaster));
                    this.commonService.menuChange.emit(menuList.moPlusLiteMbpMaster);
                }
            }

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PRO.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProbasicOus));
                    this.commonService.menuChange.emit(menuList.moProbasicOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProProUserOus));
                    this.commonService.menuChange.emit(menuList.moProProUserOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProPlusOus));
                    this.commonService.menuChange.emit(menuList.moProPlusOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moProMbpMasterOus));
                    this.commonService.menuChange.emit(menuList.moProMbpMasterOus);
                }
            }

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_PRO.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProbasicOus));
                    this.commonService.menuChange.emit(menuList.moPlusProbasicOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProProUserOus));
                    this.commonService.menuChange.emit(menuList.moPlusProProUserOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProPlusOus));
                    this.commonService.menuChange.emit(menuList.moPlusProPlusOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusProMbpMasterOus));
                    this.commonService.menuChange.emit(menuList.moPlusProMbpMasterOus);
                }
            }

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_LITE.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moLitebasicOus));
                    this.commonService.menuChange.emit(menuList.moLitebasicOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moLiteProUserOus));
                    this.commonService.menuChange.emit(menuList.moLiteProUserOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moLitePlusOus));
                    this.commonService.menuChange.emit(menuList.moLitePlusOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moLiteMbpMasterOus));
                    this.commonService.menuChange.emit(menuList.moLiteMbpMasterOus);
                }
            }

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_LITE.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusLitebasicOus));
                    this.commonService.menuChange.emit(menuList.moPlusLitebasicOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusLiteProUserOus));
                    this.commonService.menuChange.emit(menuList.moPlusLiteProUserOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPLusLitePlusOus));
                    this.commonService.menuChange.emit(menuList.moPLusLitePlusOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moPlusLiteMbpMasterOus));
                    this.commonService.menuChange.emit(menuList.moPlusLiteMbpMasterOus);
                }
            }
        }

        if (this.global.getVal('loggedOrgType') === AppEnum.customerType.ADV) {
            if (
                this.global.getVal('loggedCustId') === false &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
            ) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusProProUser));
                this.commonService.menuChange.emit(menuList.advPlusProProUser);
            }

            if (this.global.getVal('loggedCustId') === false && this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER) {
                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusProProUserOus));
                this.commonService.menuChange.emit(menuList.advPlusProProUserOus);
                return;
            }

            /**
             * AG-2504 -----------------------------------------------------------------------------------
             */
            if (
                [AppConstants.USER_OPERATION_ADMINISTRATOR, AppConstants.USER_OPERATION_STAFF_USER].indexOf(
                    this.global.getVal('userRole')
                ) > -1
            ) {
                if (this.global.getVal('isPool') === 0 && this.global.getVal('isSponsor')) {
                    if (
                        !this.global.getVal('haveCustomerAccess') &&
                        this.global.getVal('userPlanId').toString() !== AppConstants.PLAN_TYPE_MBP_MASTER.toString()
                    ) {
                        if (this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR) {
                            this.global.setVal(
                                'menuCombination',
                                JSON.stringify(this.menuListClone.menuListClone.advSponsorMBPForMBpUserPlusProLite)
                            );
                            this.commonService.menuChange.emit(menuList.advSponsorMBPForMBpUserPlusProLite);
                        } else {
                            this.global.setVal(
                                'menuCombination',
                                JSON.stringify(this.menuListClone.menuListClone.moSponsorMBPForMBpUserPlusProLiteStaff)
                            );
                            this.commonService.menuChange.emit(menuList.moSponsorMBPForMBpUserPlusProLiteStaff);
                        }

                        return;
                    } else if (!this.global.getVal('haveCustomerAccess')) {
                        if (
                            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PRO.toString() ||
                            this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_LITE.toString()
                        ) {
                            if (this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR) {
                                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advSponsorMBP));
                                this.commonService.menuChange.emit(menuList.advSponsorMBP);
                            } else {
                                this.global.setVal(
                                    'menuCombination',
                                    JSON.stringify(this.menuListClone.menuListClone.moSponsorMBPPlusProLiteMasterStaff)
                                );
                                this.commonService.menuChange.emit(menuList.moSponsorMBPPlusProLiteMasterStaff);
                            }
                        } else {
                            if (this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR) {
                                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advSponsorMBP));
                                this.commonService.menuChange.emit(menuList.moSponsorMBP);
                            } else {
                                this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moSponsorMBPStaff));
                                this.commonService.menuChange.emit(menuList.moSponsorMBPStaff);
                            }
                        }
                    } else if (
                        this.global.getVal('haveCustomerAccess') &&
                        this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString() &&
                        this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
                    ) {
                        this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moSponsorMBP));
                        this.commonService.menuChange.emit(menuList.moSponsorMBP);
                    }
                }
            }
            /**
             * -----------------------------------------------------------------------------------
             */

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_LITE.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusLitebasic));
                    this.commonService.menuChange.emit(menuList.advPlusLitebasic);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusLiteProUser));
                    this.commonService.menuChange.emit(menuList.advPlusLiteProUser);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPLusLitePlus));
                    this.commonService.menuChange.emit(menuList.advPLusLitePlus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusLiteMbpMaster));
                    this.commonService.menuChange.emit(menuList.advPlusLiteMbpMaster);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBPPro));
                    this.commonService.menuChange.emit(menuList.moMBPPro);
                }
            }

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_LITE.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusLitebasicOus));
                    this.commonService.menuChange.emit(menuList.advPlusLitebasicOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusLiteProUserOus));
                    this.commonService.menuChange.emit(menuList.advPlusLiteProUserOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPLusLitePlusOus));
                    this.commonService.menuChange.emit(menuList.advPLusLitePlusOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusLiteMbpMasterOus));
                    this.commonService.menuChange.emit(menuList.advPlusLiteMbpMasterOus);
                }
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBPProStf));
                    this.commonService.menuChange.emit(menuList.moMBPProStf);
                }
            }

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PRO.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusProbasic));
                    this.commonService.menuChange.emit(menuList.advPlusProbasic);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusProProUser));
                    this.commonService.menuChange.emit(menuList.advPlusProProUser);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusProPlus));
                    this.commonService.menuChange.emit(menuList.advPlusProPlus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusProMbpMaster));
                    this.commonService.menuChange.emit(menuList.advPlusProMbpMaster);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBP));
                    this.commonService.menuChange.emit(menuList.moMBP);
                }
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBPPro));
                    this.commonService.menuChange.emit(menuList.moMBPPro);
                }
            }

            if (
                this.global.getVal('planId').toString() === AppConstants.PLAN_TYPE_ENTERPRISE_PRO.toString() &&
                this.global.getVal('userRole') === AppConstants.USER_OPERATION_STAFF_USER
            ) {
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_BASIC_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusProbasicOus));
                    this.commonService.menuChange.emit(menuList.advPlusProbasicOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PRO_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusProProUserOus));
                    this.commonService.menuChange.emit(menuList.advPlusProProUserOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_PLUS_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusProPlusOus));
                    this.commonService.menuChange.emit(menuList.advPlusProPlusOus);
                }

                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_MASTER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.advPlusProMbpMasterOus));
                    this.commonService.menuChange.emit(menuList.advPlusProMbpMasterOus);
                }
                if (this.global.getVal('userPlanId').toString() === AppConstants.PLAN_TYPE_MBP_USER.toString()) {
                    this.global.setVal('menuCombination', JSON.stringify(this.menuListClone.menuListClone.moMBPProStf));
                    this.commonService.menuChange.emit(menuList.moMBPProStf);
                }
            }
        }
    }

    /**
     * this function will be used to remove notification
     *
     * @param id
     */
    removeNotification(id: number): void {
        const param = {
            id
        };
        const confirmMessage = new ConfirmationMessage();
        confirmMessage.type = AlertMessage.Type.warning;
        confirmMessage.title = '';
        confirmMessage.isShowCancelButton = true;
        confirmMessage.text = 'Are you sure you want to delete this notification?';
        confirmMessage.confirmButtonText = 'Ok';
        confirmMessage.okCallBack = async () => {
            Util.showLoading();
            this.service
                .callGetApi(ApiUrlConfig.Header.removeNotification, param)
                .then(() => {
                    Util.hideLoading();
                    const alertMessage = new AlertMessage();
                    alertMessage.type = AlertMessage.Type.success;
                    alertMessage.text = 'Notification deleted successfully .';
                    alertMessage.title = 'Great';
                    Util.showTextMessage(alertMessage);
                    this.getNotification();
                })
                .catch((error: HttpErrorResponse) => {
                    Util.hideLoading();
                    // Caller can handle via status or they can emit the event to handle at common level
                    this.commonService.commonErrorEvent.emit(error);
                });
        };
        Util.showConfirmationDialog(confirmMessage);
        // Call the service to call the API service and get the data from server
    }

    // Function to redirect user to a page depending on user plan and crops in current year
    private redirect(isCustomerChanged = false): void {
        let isReload = false;

        if (this._router.url.includes('resources/HedgeRecommendations')) {
            const userPlanId = this.global.getVal('userPlanId');
            const planId = this.global.getVal('planId');
        
            if (this.disableHedgeRecomPlanTypes.includes(userPlanId) || this.disableHedgeRecomPlanTypes.includes(planId)) {
                this.disableMenuItemByName("Hedge Recommendations");
            }else{
                return;
            }
        }else if(this._router.url.includes('resources/HedgeReportsAudio')){
            return;
        }

        switch (this.global.getVal('userPlanId')) {
            case AppConstants.PLAN_TYPE_AGYIELD_PLUS:
            case AppConstants.PLAN_TYPE_AGYIELD_PRO:
            case AppConstants.PLAN_TYPE_ENTERPRISE_PRO:
            case AppConstants.PLAN_TYPE_ENTERPRISE_LITE:
            case AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_PRO:
            case AppConstants.PLAN_TYPE_ENTERPRISE_PLUS_LITE:
            case AppConstants.PLAN_TYPE_BASIC_USER:
            case AppConstants.PLAN_TYPE_MBP_MASTER:
                this.checkCropCount(isCustomerChanged);
                break;
            case AppConstants.PLAN_TYPE_MBP_USER:
                if (this._router.url.includes('/agyield-mbp')) {
                    isReload = true;
                } else {
                    this._router.navigate(['/agyield-mbp']);
                    if (!isCustomerChanged) {
                        return;
                    }
                }
                break;
            default:
                if (this._router.url.includes('/dashboard') || this._router.url.includes('/pen-dashboard')) {
                    isReload = true;
                } else {
                    if(this.global.getVal('isLivestock') && this.selectedStockType == 'livestock'){
                        this._router.navigate(['/pen-dashboard']);
                    } else{
                        this._router.navigate(['/dashboard']);
                    }
                    
                }

                break;
        }

        if (isReload || isCustomerChanged) {
            window.location.reload();
        }
    }

    // This function is used to determine if the selected user has crops for current year or not
    // then redirect to appropriate page based on that.
    private checkCropCount(isCustomerSwitched: boolean): void {
        let isReload = true;
        let accountSummary = (this.global.getVal('isLivestock') && this.selectedStockType == 'livestock')? '/pen-account-summary' : '/account-summary';
        let cropCount = (this.global.getVal('isLivestock') && this.selectedStockType == 'livestock')? this.global.getVal('penCount') : this.global.getVal('cropCount');
        let cropOrPen = (this.global.getVal('isLivestock') && this.selectedStockType == 'livestock')? '/pens' : '/crops';

        if (this.global.getVal('status') === 'SUP' && this.global.getVal('isPool') > 0) {
            this._router.navigate(['/agyield-mbp']);
            if (!isCustomerSwitched) {
                return;
            }
        }

        // if sales staff user (With MBP master or end user selection from user drop-down)
        // if has not crop for the year then move to entity page
        // if user is basic user then crops page and if the user is mbp master then to the transactions list page
        if (this.global.getVal('userRole').toString() === AppConstants.USER_AY_SALES.toString()) {
            if (this._router.url.includes('/agyield-mbp')) {
                isReload = true;
            } else {
                this._router.navigate(['/agyield-mbp']);
                if (!isCustomerSwitched) {
                    return;
                }
            }
        } else if (cropCount > 0 && this.global.getVal('haveCustomerAccess')) {
            if (this._router.url.includes(accountSummary)) {
                isReload = true;
            } else {
                this._router.navigate([accountSummary]);
                if (!isCustomerSwitched) {
                    return;
                }
            }
        } else {
            if (this.global.getVal('userPlanId') === AppConstants.PLAN_TYPE_BASIC_USER) {
                if (this._router.url.includes(cropOrPen)) {
                    isReload = true;
                } else {
                    this._router.navigate([cropOrPen]);
                    if (!isCustomerSwitched) {
                        return;
                    }
                }
            } else if (
                this.global.getVal('userPlanId') === AppConstants.PLAN_TYPE_MBP_MASTER &&
                !(
                    this.global.getVal('loggedOrgType') === AppEnum.customerType.MO ||
                    this.global.getVal('loggedOrgType') === AppEnum.customerType.ADV
                )
            ) {
                if (this._router.url.includes('/transactions')) {
                    isReload = true;
                } else {
                    if (
                        this.global.getVal('userRole') === AppConstants.USER_OPERATION_ADMINISTRATOR &&
                        this.global.getVal('isPool') === 0 &&
                        this.global.getVal('isSponsor')
                    ) {
                        this._router.navigate(['/agyield-mbp']);
                    } else {
                        this._router.navigate(['/transactions']);
                    }
                    if (!isCustomerSwitched) {
                        return;
                    }
                }
            } else if (this.global.getVal('haveCustomerAccess')) {
                if (this._router.url.includes(accountSummary)) {
                    if (cropCount === 0) {
                        if (this.global.getVal('isFreeTrialAccount') || this.global.getVal('isSelectedFreeTrialAccount')) {
                            this._router.navigate([cropOrPen]);
                        } else {
                            this._router.navigate(['/entities']);
                        }
                    }
                    isReload = false;
                    return;
                } else {
                    if (this.global.getVal('isFreeTrialAccount') || this.global.getVal('isSelectedFreeTrialAccount')) {
                        this._router.navigate([cropOrPen]);
                    } else {
                        this._router.navigate(['/entities']);
                    }
                    if (!isCustomerSwitched) {
                        return;
                    }
                }
            } else {
                if (this._router.url.includes('/agyield-mbp')) {
                    isReload = true;
                } else {
                    this._router.navigate(['/agyield-mbp']);
                    if (!isCustomerSwitched) {
                        return;
                    }
                }
            }
        }

        const isCustomerChanged: boolean = this._webStorageService.get('is_customer_change');
        if (
            isReload &&
            (isCustomerChanged || this._webStorageService.get('is_first_time') || this._webStorageService.get('is_year_change'))
        ) {
            this._webStorageService.set('is_customer_change', false);
            this.resetWebStorage();
            window.location.reload();
        }
        // else {
        //   this.getActiveCrops(Number(this.selectedYear));
        // }
    }

    private isPathAllowed(): boolean {
        let isPathAllowed = false;
        if (this._router.url.includes('/entry')) {
            isPathAllowed = true;
        }

        if (this._router.url.includes('/CropProphetWebsite')) {
            this._webStorageService.set('is_year_change', true);
        }

        const allowedPathLength = this.allowedPathsExceptMenu.length;
        for (let i = 0; i < allowedPathLength; i++) {
            if (this._router.url.includes(this.allowedPathsExceptMenu[i])) {
                isPathAllowed = true;
            }
        }

        const menuCount = this.navItems.length;
        for (let i = 0; i < menuCount; i++) {
            for (const [key, value] of Object.entries(this.navItems[i])) {
                if (typeof value !== 'object' && key === 'name' && value === 'Dashboard') {
                    if (this._router.url.includes(this.navItems[i].url) && !('disabled' in this.navItems[i])) {
                        isPathAllowed = true;
                        break;
                    }
                }

                if (typeof value === 'object' && value !== null && key === 'children') {
                    const val: ayObject = value;
                    const childMenuCount = val.length;
                    for (let j = 0; j < childMenuCount; j++) {
                        if (this._router.url.includes(val[j].url) && !('attributes' in val[j] && 'disabled' in val[j].attributes)) {
                            isPathAllowed = true;
                            break;
                        }
                    }
                }
            }
            if (isPathAllowed) {
                break;
            }
        }
        return isPathAllowed;
    }

    // Redirect to my account
    showMyAccount(): void {
        this._router.navigate(['/my-account']);
    }
    // Redirect to Settings
    showSettings(): void {
        this._router.navigate(['/settings']);
    }

    // Redirect to renew page
    showRenewPage(): void {
        const userId = btoa(String(this.global.app.loggedInUser.id));
        this._router.navigate(['renew-plan', userId]);
    }

    getDateWithFormat(startDate: string): string {
        const newFormatDateArr = startDate.split('-');
        const newDayTimeArr = newFormatDateArr[2].split(' ');
        const newYear1 = newFormatDateArr[0];
        const newMonth1 = newFormatDateArr[1];
        const newDay1 = newDayTimeArr[0];
        const newTime1 = newDayTimeArr[1];
        const newFormatDate = newMonth1 + '/' + newDay1 + '/' + newYear1 + ' ' + newTime1;

        const date = new Date(newFormatDate);
        let hours = date.getHours();
        let minutes: number;
        minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? 0 + minutes : minutes;
        const strTime = hours + ':' + minutes + ' ' + ampm;
        const notificationDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear() + '  ' + strTime;

        return notificationDate;
    }

    getObjectLength(obj: ayObject): number {
        return Object.keys(obj).length;
    }

    resetWebStorage(): void {
        if (!(this._webStorageService.get('is_first_time') && this._webStorageService.get('is_year_change'))) {
            this._webStorageService.set('is_year_change', false);
        }

        this._webStorageService.set('is_first_time', false);
    }

    /**
     * Function to set the home link based on first enabled menu item
     *
     * @author Manesh Raval
     */
    private setHomeLink(): void {
        this.homeLink = '\\';
        let isSet = false;
        if (this.navItems[0] && this.navItems[0].name === 'Dashboard') {
            this.homeLink = this.navItems[0].url;
        } else {
            for (const navItem of this.navItems) {
                if (navItem.children) {
                    for (const item of navItem.children) {
                        if (
                            (item.hasOwnProperty('attributes') &&
                                item.attributes.hasOwnProperty('disabled') &&
                                item.attributes.disabled === true) ||
                            !item.hasOwnProperty('attributes')
                        ) {
                            this.homeLink = item.url;
                            isSet = true;
                            break;
                        }
                    }
                }
                if (isSet) {
                    break;
                }
            }
        }
    }
    /**
     * Function to disable resource menu items
     *
     * @param obj
     * @returns
     */
    disableCropProphetWebsite(obj: ayObject): ayObject {
        obj.attributes = {
            disabled: true
        };
        if (obj.children !== undefined && obj.children.length > 0) {
            for (let i = 0; i < obj.children.length; i++) {
                if (obj.children[i].name === 'CropProphet') {
                    for (let j = 0; j < obj.children[i].children.length; j++) {
                        if (
                            this.global.getVal('isCropProphet') === 0 &&
                            obj.children[i].children[j].name.trimStart() === 'CropProphet Website'
                        ) {
                            obj.children[i].children[j].attributes = {
                                disabled: true
                            };
                        }
                    }
                }
            }
        }
        return obj;
    }

    /**
     * Function to disable resource menu items
     *
     * @param string
     * @returns
     */
    setStockType(stockType : string ): void {
        const param = {
            stockType
        };
        this.service
            .callPostApi(ApiUrlConfig.Header.setUserSelectedStockType, param)
            .then(() => {
                Util.hideLoading();
                this.global.setVal('selectedStockType', stockType);
                this.selectedStockType = stockType.toString();
                this._webStorageService.set('is_year_change', true);
                //window.location.reload();
                this.checkCropCount(true);
            })
            .catch((error: HttpErrorResponse) => {
                Util.showLoading();
                this.commonService.commonErrorEvent.emit(error);
            });
    }

    isPenDataExist() {
        this.service
            .callPostApi(ApiUrlConfig.Pen.getPenExistByOrganizationId, {})
            .then((response : any) => {
                Util.hideLoading();
                if(response.isPenExist) {
                    this.global.setVal('isLivestock', 1);
                }
            })
            .catch((error: HttpErrorResponse) => {
                Util.showLoading();
                this.commonService.commonErrorEvent.emit(error);
            });
    }
}
